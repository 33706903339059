import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
} from '@rxweb/reactive-form-validators';

export class CreateCustomerGroupModel {


  @required()
  @maxLength({ value: 60 })
  @pattern({ expression: { Alphanumeric: /^[a-zA-Z0-9 ]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  GroupName: string;

  @prop()
  CustomerCount: number = 0;

  @prop()
  Status: string = 'Draft';

  @prop()
  IsActive: boolean= true;
}
