import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
} from '@rxweb/reactive-form-validators';

export class UpdateCustomerGroupModel {

  @required()
  @prop()
  CustomerGroupId : string;

  @required()
  @maxLength({ value: 60 })
  @pattern({ expression: { Alphanumeric: /^[a-zA-Z0-9 ]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  GroupName: string;

  @prop()
  Status: string = 'Draft';

  @required()
  @prop()
  AchTransactionType: string

  @prop()
  IsActive: boolean= true;

  //@required()
  @prop()
  CreateCustomerGroupDetails: CustomerGroupDetails[]
}


 class CustomerGroupDetails{
  @prop()
  CustomerGroupId : string;

  @prop()
  CustomerId: string;

  @prop()
  DefaultAmount:number

  @prop()
  IsActive : boolean = true;
}