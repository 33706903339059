import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AchOriginationReportsService } from 'src/app/featureModules/services/ach.origination.reports.service';
import { CardsOriginationReportsService } from 'src/app/featureModules/services/cards.origination.reports.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { DownloadOriginationReportModel } from 'src/app/featureModules/models/download-origination-report.model';

@Component({
  selector: 'app-origination-report',
  templateUrl: './origination-report.component.html',
  styleUrls: ['./origination-report.component.scss'],
})
export class OriginationReportComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  @Input()
  actionRefresh = false;

  @Input()
  noRecordMessage: string;

  isAchLoading: boolean;
  isCardsLoading: boolean;
  loadMessage: string = 'Please wait...';
  totalAchDebitAmount: number = 0;
  totalAchDebitCount: number = 0;
  totalAchCreditAmount: number = 0;
  totalAchCreditCount: number = 0;
  totalCardsDebitAmount: number = 0;
  totalCardsDebitCount: number = 0;
  totalAmount: number = 0;
  totalCount: number = 0;
  batchDate: any;

  achData: any;
  cardsData: any;
  downloadOriginationReportModel: DownloadOriginationReportModel;

  achPage: PageChanged = {
    pageIndex: 0,
    pageSize: 25,
    length: 100,
    previousPageIndex: 0,
  };
  achSortBy: SortChanged = {
    active: 'effectiveDate',
    direction: 'desc',
  };

  cardsPage: PageChanged = {
    pageIndex: 0,
    pageSize: 25,
    length: 100,
    previousPageIndex: 0,
  };
  cardsSortBy: SortChanged = {
    active: 'effective',
    direction: 'desc',
  };

  achColumns: TableColumn[] = [
    { displayName: '', name: 'icon' },
    { displayName: 'NAME', name: 'name' },
    { displayName: 'INVOICE NO.', name: 'invoiceNumber' },
    { displayName: 'TRACKING ID', name: 'trackingId' },
    { displayName: 'TRANSACTION TYPE', name: 'entryCode' },
    { displayName: 'PAYMENT TYPE', name: 'PaymentType' },
    { displayName: 'ACCOUNT NO.', name: 'BankAccountNumber' },
    { displayName: 'TOTAL CONVEN. FEE', name: 'surchargeAmount',className:'mL'},
    { displayName: 'AMOUNT', name: 'Amount' },
    { displayName: 'CREATED DATE', name: 'createdDate' },
    { displayName: 'SENT DATE', name: 'sentDate' },
    { displayName: 'EFFECTIVE DATE', name: 'effectiveDate' },
    { displayName: 'RECURRING', name: 'isRecurring' },
  ];

  creditDebiColumns: TableColumn[] = [
    { displayName: '', name: 'icon'},
    { displayName: 'NAME', name: 'name'},
    { displayName: 'INVOICE NO.', name: 'invoiceNumber'},
    { displayName: 'TRACKING ID', name: 'transactionId'},
    { displayName: 'PAYMENT TYPE', name: 'cardPaymentType'},
    { displayName: 'CARD NO.', name: 'cardNumber'},
    { displayName: 'TOTAL CONVEN. FEE', name: 'surchargeAmount',className:'mL'},
    { displayName: 'AMOUNT', name: 'Amount'},
    { displayName: 'CREATED DATE', name: 'createdDate'},
    { displayName: 'SENT DATE', name: 'sentDate'},
    { displayName: 'EFFECTIVE DATE', name: 'effectiveDate'},
    { displayName: 'RECURRING', name: 'isRecurring'},
  ];

  achAllColumns: TableColumn[];
  cardsAllColumns: TableColumn[];

  constructor(
    private router: Router,
    private _commonService: CommonService,
    private _toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public _achReportService: AchOriginationReportsService,
    public _cardsReportService: CardsOriginationReportsService
  ) {}

  ngOnInit(): void {
    this.batchDate = this.activatedRoute.snapshot.paramMap.get('batchDate');
    this.downloadOriginationReportModel = new DownloadOriginationReportModel();
    if (this.batchDate) {
      this.GenerateReport();
    } else {
      this.router.navigate(['reports']);
    }
  }

  GenerateReport() {
    this.achData = [];
    this.cardsData = [];
    this.getAchSortAndPagination();
    this.GenerateReportForAch();
    this.getCardsSortAndPagination();
    this.GenerateReportForCards();
  }

  GenerateReportForAch() {
    this.achAllColumns = this.achColumns;
    this.isAchLoading = true;

    this._achReportService
      .GetDailyReport(
        this.batchDate,
        this.achSortBy.active,
        this.achSortBy.direction,
        this.achPage.pageIndex,
        this.achPage.pageSize
      )
      .subscribe(
        (data) => {
          this.isAchLoading = false;
          if (data.data != null) {
            this.achData = data.data.transactions;
            this.totalCount = data.data.totalNumberOfTransactions;
            this.totalAmount = data.data.batchTotal;
            this.totalAchCreditCount = data.data.numberOfCredits;
            this.totalAchCreditAmount = data.data.totalCredits;
            this.totalAchDebitCount = data.data.numberOfDebits;
            this.totalAchDebitAmount = data.data.totalDebits;
            if (this.achData && this.achData.length) {
              this.achData.forEach((element) => {
                element.PaymentType = element.paymentType.toUpperCase();
                element.BankAccountNumber = element.bankAccountNumber;
                element.invoiceNumber = element.invoiceNumber;
                element.trackingId = element.trackingId;
                element.entryCode = element.transactionType;
                element.sentDate = element.sendDate;
                element.effectiveDate = element.effectiveDate;
                element.entryDate = element.createdDate;
                element.Amount = element.amount;
                element.isRecurring = element.recurring;
                element.surchargeAmount = element.surchargeAmount;
              });
            }
            this.achPage.length = data.data.totalNumberOfTransactions;
          } else {
            this.achData = [];
          }
        },
        (error) => {
          this.isAchLoading = false;
          this.loadMessage = '';
          if(error.status !== 403) {            
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        }
      );
  }

  GenerateReportForCards() {
    this.cardsAllColumns = this.creditDebiColumns;
    this.isCardsLoading = true;
    this._cardsReportService
      .GetDailyReport(
        this.batchDate,
        this.cardsSortBy.active,
        this.cardsSortBy.direction,
        this.cardsPage.pageIndex,
        this.cardsPage.pageSize
      )
      .subscribe(
        (data) => {
          this.isCardsLoading = false;
          if (data.data != null) {
            this.cardsData = data.data.transactions;
            this.totalCardsDebitCount = data.data.totalNumberOfTransactions;
            this.totalCardsDebitAmount = data.data.total;
            if (this.cardsData && this.cardsData.length) {
              this.cardsData.forEach((element) => {
                element.cardPaymentType = 'DEBIT';
                element.cardNumber = element.cardNumber;
                element.invoiceNumber = element.invoiceNumber;
                element.transactionId = element.trackingId;
                element.effectiveDate = element.settlementDate;
                element.createdDate = element.transactionDate;
                element.sentDate = element.settlementDate;
                element.Amount = element.amount;
                element.surchargeAmount = element.surcharge;
                element.isRecurring = element.recurring;
              });
            }
            this.cardsPage.length = data.data.totalNumberOfTransactions;
          } else {
            this.cardsData = [];
          }
        },
        (error) => {
          this.isCardsLoading = false;
          this.loadMessage = '';          
          if(error.status !== 403) {            
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        }
      );
  }

  getAchSortAndPagination() {
    this.achPage = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'achReportGridPageIndex',
          'achOriginationReport',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'achReportGridPageSize',
          'achOriginationReport',
          25
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.achSortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'achReportGridSortActive',
        'achOriginationReport',
        this.achSortBy.active
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'achReportGridSortDirection',
        'achOriginationReport',
        this.achSortBy.direction
      ),
    };
  }

  getCardsSortAndPagination() {
    this.cardsPage = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'cardsReportGridPageIndex',
          'cardsOriginationReport',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'cardsReportGridPageSize',
          'cardsOriginationReport',
          25
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };
    this.cardsSortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'cardsReportGridSortActive',
        'cardsOriginationReport',
        this.cardsSortBy.active
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'cardsReportGridSortDirection',
        'cardsOriginationReport',
        this.cardsSortBy.direction
      ),
    };
  }

  // Server side Pagination
  onAchPageChanged(event: PageChanged) {
    if (
      this.achPage.pageIndex !== event.pageIndex ||
      this.achPage.pageSize !== event.pageSize
    ) {
      this.achPage = event;

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'achReportGridPageSize',
        'achOriginationReport'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'achReportGridPageIndex',
        'achOriginationReport'
      );
    }
    this.GenerateReportForAch();
  }
  // Server side Pagination
  onCardsPageChanged(event: PageChanged) {
    if (
      this.cardsPage.pageIndex !== event.pageIndex ||
      this.cardsPage.pageSize !== event.pageSize
    ) {
      this.cardsPage = event;

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'cardsReportGridPageSize',
        'cardsOriginationReport'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'cardsReportGridPageIndex',
        'cardsOriginationReport'
      );
    }
    this.GenerateReportForCards();
  }
  // Server side Sorting
  onAchSortChanged(event: SortChanged) {
    var column = this.achColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.achSortBy.active !== event.active ||
      this.achSortBy.direction !== event.direction
    ) {
      this.achSortBy = event;
      this.sortByMappingAch();

      this._commonService.setListDataToLocalStorage(
        event.active,
        'achReportGridSortActive',
        'achOriginationReport'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'achReportGridSortDirection',
        'achOriginationReport'
      );
    }
    this.GenerateReportForAch();
  }

  sortByMappingAch() {
    switch (this.achSortBy.active) {
      case 'name':
        this.achSortBy.active = 'firstName';
        break;
    }
  }
  // Server side Sorting
  onCardsSortChanged(event: SortChanged) {
    var column = this.creditDebiColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.cardsSortBy.active !== event.active ||
      this.cardsSortBy.direction !== event.direction
    ) {
      this.cardsSortBy = event;
      this.sortByMappingCards();

      this._commonService.setListDataToLocalStorage(
        event.active,
        'cardsReportGridSortActive',
        'cardsOriginationReport'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'cardsReportGridSortDirection',
        'cardsOriginationReport'
      );
    }
    this.GenerateReportForCards();
  }

  sortByMappingCards() {
    switch (this.cardsSortBy.active) {
      case 'name':
        this.cardsSortBy.active = 'firstName';
        break;
      case 'effectiveDate':
        this.cardsSortBy.active = 'effective';
        break;
      case 'sentDate':
        this.cardsSortBy.active = 'sent';
        break;
    }
  }

  ngOnDestroy() {
    if (
      !(
        this.router.url.includes('origination-report') ||
        this.router.url.includes('origination-report-filter')
      )
    ) {
      localStorage.removeItem('achOriginationReport');
      localStorage.removeItem('cardsOriginationReport');
    }
  }

  onManageFilterClick() {
    this.router.navigate(['reports/origination-report-filter']);
  }

  downloadReportToExcel() {
    this.sortByMappingAch();
    this.sortByMappingCards();
    this.downloadOriginationReportModel.batchDate = this.batchDate; 
    this.downloadOriginationReportModel.achSortBy = this.achSortBy.active;
    this.downloadOriginationReportModel.achSortDirection = this.achSortBy.direction;
    this.downloadOriginationReportModel.cardSortBy = this.cardsSortBy.active;
    this.downloadOriginationReportModel.cardSortDirection = this.cardsSortBy.direction;        
    this._cardsReportService.DownloadReportForOrigination(this.downloadOriginationReportModel);
  }
}
