import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersComponent } from './blockModules/pages/customers/customers.component';
import { DashboardComponent } from './blockModules/pages/dashboard/dashboard.component';
import { InstantVerifyComponent } from './blockModules/pages/instant-verify/instant-verify.component';
import { LoginComponent } from './blockModules/pages/login/login.component';
import { TransactionsComponent } from './blockModules/pages/transactions/transactions.component';
import { AddCustomerComponent } from './featureModules/components/customer-related/add-customer/add-customer.component';
import { CustomerProfileComponent } from './featureModules/components/customer-related/customer-profile/customer-profile.component';
import { DashboardReportServiceBasedComponent } from './featureModules/components/dashboard-related/dashboard-report-service-based/dashboard-report-service-based.component';
import { DashboardReportTotalTransactionsComponent } from './featureModules/components/dashboard-related/dashboard-report-total-transactions/dashboard-report-total-transactions.component';
import { AddInstantBankVerifyComponent } from './featureModules/components/instant-verify-related/add-instant-bank-verify/add-instant-bank-verify.component';
import { CustomerRefreshDetailsComponent } from './featureModules/components/instant-verify-related/customer-refresh-details/customer-refresh-details.component';
import { InstantVerifyReportComponent } from './featureModules/components/instant-verify-related/instant-verify-report/instant-verify-report.component';
import { SendOtpComponent } from './blockModules/pages/login/send-otp/send-otp.component';
import { ForgotPasswordComponent } from './blockModules/pages/login/forgot-password/forgot-password.component';
import { ForgotPasswordStep1Component } from './blockModules/pages/login/forgot-password-step1/forgot-password-step1.component';
import { ForgotPasswordFinishComponent } from './blockModules/pages/login/forgot-password-finish/forgot-password-finish.component';
import { AddCreditdebitTransactionComponent } from './featureModules/components/transactions-related/creditdebit/add-creditdebit-transaction/add-creditdebit-transaction.component';
import { CustomerCreditdebitTransactionComponent } from './featureModules/components/transactions-related/creditdebit/customer-creditdebit-transaction/customer-creditdebit-transaction.component';
import { AddEcheckTransactionComponent } from './featureModules/components/transactions-related/echeck/add-echeck-transaction/add-echeck-transaction.component';
import { CustomerEcheckTransactionComponent } from './featureModules/components/transactions-related/echeck/customer-echeck-transaction/customer-echeck-transaction.component';
import { AddInstantFundTransactionComponent } from './featureModules/components/transactions-related/instantfund/add-instantfund-transaction/add-instantfund-transaction.component';
import { CustomerInstantFundTransactionComponent } from './featureModules/components/transactions-related/instantfund/customer-instantfund-transaction/customer-instantfund-transaction.component';
import { GenerateReportsComponent } from './featureModules/components/report-related/generate-report/generate-report.component';
import { GenerateReportGridComponent } from './featureModules/components/report-related/generate-report-grid/generate-report-grid.component';
import { TransactionsModule } from './blockModules/pages/transactions/transactions.module';
import { UserManagementModule } from './blockModules/pages/user-management/user-management.module';
import { ReportsComponent } from './blockModules/pages/reports/reports.component';
import { AuthGuard } from './sharedModules/guards/auth.guard';
import { PagenotfoundComponent } from './sharedModules/components/pagenotfound/pagenotfound.component';
import { AccountSummaryComponent } from './featureModules/components/transactions-related/account-summary/account-summary.component';
import { StatementsComponent } from './blockModules/pages/statements/statements.component';
import { InvoiceComponent } from './blockModules/pages/invoice/invoice.component';
import { GenerateInvoiceComponent } from './featureModules/components/invoice-related/generate-invoice/generate-invoice.component';
import { SettingsComponent } from './featureModules/components/settings-related/setting/settings/settings.component';
import { InvoiceDetailComponent } from './featureModules/components/invoice-related/invoice-detail/invoice-detail.component';
import { ChallengerScoreUrls } from './blockModules/pages/challenger-score/challenger-score-settings-constants';
import { ChallengerScoreSettingsComponent } from './blockModules/pages/challenger-score/challenger-score-settings.component';
import { OtherComponent } from './blockModules/pages/other/other.component';
import { AddInventoryItemComponent } from './featureModules/components/others-related/inventory-related/add-inventory-item/add-inventory-item.component';
import { TicketComponent } from './blockModules/pages/ticket/ticket.component';
import { CreateTicketComponent } from './featureModules/components/ticket-related/create-ticket/create-ticket.component';
import { TicketDetailsComponent } from './featureModules/components/ticket-related/ticket-details/ticket-details.component';
import { TicketUpdateComponent } from './featureModules/components/ticket-related/ticket-update/ticket-update.component';

import { CustomerScoreStatsModule } from './blockModules/pages/customer-score-stats/customer-score-stats.module';
import { AddUpdateInventoryCategoryComponent } from './featureModules/components/others-related/inventory-related/add-update-inventory-category/add-update-inventory-category.component';
import { InventoryCategoryListComponent } from './featureModules/components/others-related/inventory-related/inventory-category-list/inventory-category-list.component';

import { PaymentInformationComponent } from './externalModules/paymentWidget/pages/payment-information/payment-information.component';
import { PaymentOrderComponent } from './externalModules/paymentWidget/pages/payment-order/payment-order.component';
import { PaymentSuccessComponent } from './externalModules/paymentWidget/pages/payment-success/payment-success.component';
import { PaymentFailureComponent } from './externalModules/paymentWidget/pages/payment-failure/payment-failure.component';

import { SettingComponent } from './blockModules/pages/setting/setting.component';
import { EmailSetupComponent } from './featureModules/components/settings-related/email-setup/email-setup.component';
import { InvoiceSetupComponent } from './featureModules/components/settings-related/invoice-setup/invoice-setup.component';
import { ManageNotificationComponent } from './featureModules/components/settings-related/manage-notification/manage-notification.component';
import { HostedPaymentLinkConfigurationComponent } from './featureModules/components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/hosted-payment-link-configuration.component';
import { HostedPaymentLinkListComponent } from './featureModules/components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-list/hosted-payment-link-list.component';
import { HostedPaymentPageSettingMasterLayoutComponent } from './featureModules/components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-setting-master-layout/hosted-payment-page-setting-master-layout.component';
import { AdminLoginComponent } from './blockModules/pages/admin-login/admin-login.component';
import { BillingComponent } from './blockModules/pages/billing/billing.component';
import { BillingProfileComponent } from './featureModules/components/billing-related/billing-profile/billing-profile.component';
import { BillingListComponent } from './featureModules/components/billing-related/billing-list/billing-list.component';
import { OriginationReportComponent } from './featureModules/components/report-related/origination-report/origination-report.component';
import { OriginationReportFilterComponent } from './featureModules/components/report-related/origination-report-filter/origination-report-filter.component';
import { CustomerGroupComponent } from './featureModules/components/customer-related/customer-group/customer-group.component';
import { ViewCustomerGroupComponent } from './featureModules/components/customer-related/view-customer-group/view-customer-group.component';
import { NonMfaLoginComponent } from './externalModules/nonMFA/pages/login/non-mfa-login/non-mfa-login.component';
import { NonMfaLoginFailureComponent } from './externalModules/nonMFA/pages/login/non-mfa-login-failure/non-mfa-login-failure.component';
import { NonMfaLoginFrameComponent } from './externalModules/nonMFA/pages/login/non-mfa-login-frame/non-mfa-login-frame.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'non-mfa/:clientId/:emailId/:token',
    component: NonMfaLoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'non-mfa/frame/:clientId/:emailId/:token',
    component: NonMfaLoginFrameComponent,
    pathMatch: 'full'
  },
  {
    path: 'non-mfa/failure/:clientId/:emailId/:token',
    component: NonMfaLoginFailureComponent,
    pathMatch: 'full'
  },
  {
    path: 'payment/:token',
    component: PaymentOrderComponent,
    pathMatch: 'full',
  },
  {
    path: 'payment/failure/:token',
    component: PaymentFailureComponent,
    pathMatch: 'full',
  },
  {
    path: 'payment/details/:token',
    component: PaymentInformationComponent,
    pathMatch: 'full',
  },
  {
    path: 'payment/confirmation/:token',
    component: PaymentSuccessComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgotPasswordEmail',
    component: ForgotPasswordStep1Component,
    pathMatch: 'full',
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgotPassword/:token',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgotPasswordFinish',
    component: ForgotPasswordFinishComponent,
    pathMatch: 'full',
  },
  {
    path: 'sendOTP',
    component: SendOtpComponent,
    pathMatch: 'full',
  },
  {
    path: 'csr',
    component: AdminLoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/customer-profile/:customerId',
    component: CustomerProfileComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers',
    component: CustomersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/:selectedIndex',
    component: CustomersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'invoices',
    component: InvoiceComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'invoices/generate-invoice',
    component: GenerateInvoiceComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'invoices/generate-invoice/:customerId',
    component: GenerateInvoiceComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'invoices',
    component: InvoiceComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'invoices/invoices-details',
    component: InvoiceDetailComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'addcustomer',
    component: AddCustomerComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/manage-customer',
    component: AddCustomerComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-group',
    component: CustomerGroupComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-group/:customerGroupId',
    component: CustomerGroupComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-group/:customerGroupId/:groupName',
    component: CustomerGroupComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'view-customer-group/:customerGroupId',
    component: ViewCustomerGroupComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/manage-customer/:step/:customerId/:cardId',
    component: AddCustomerComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/manage-customer/:customerId',
    component: AddCustomerComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'instantBankAnalysis',
    component: InstantVerifyComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'instantBankAnalysis/customer-refresh-details/:customerId/:bankVerificationId',
    component: CustomerRefreshDetailsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'instantBankAnalysis/add-ibv',
    component: AddInstantBankVerifyComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/add-creditdebit',
    component: AddCreditdebitTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/add-creditdebit/:customerId',
    component: AddCreditdebitTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/add-creditdebit/:customerId',
    component: AddCreditdebitTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/customer-creditdebit/:customerId/:tabIndex',
    component: CustomerCreditdebitTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'instantBankAnalysis/ibv-report/:customerId/:bankVerificationId',
    component: InstantVerifyReportComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/add-echeck',
    component: AddEcheckTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/add-echeck/:customerId',
    component: AddEcheckTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/add-echeck/:customerId',
    component: AddEcheckTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/customer-echeck/:customerId/:tabIndex',
    component: CustomerEcheckTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/dashboard-report',
    component: DashboardReportTotalTransactionsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/dashboard-report-service-based/:product',
    component: DashboardReportServiceBasedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/customer-instantfund/:customerId/:tabIndex',
    component: CustomerInstantFundTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/add-instantfund',
    component: AddInstantFundTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions/add-instantfund/:customerId',
    component: AddInstantFundTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/add-instantfund/:customerId',
    component: AddInstantFundTransactionComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/generate-report',
    component: GenerateReportsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/generate-report-data',
    component: GenerateReportGridComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/origination-report/:batchDate',
    component: OriginationReportComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/origination-report-filter',
    component: OriginationReportFilterComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'statements/:id/:tabIndex',
    component: AccountSummaryComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'statements',
    component: StatementsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: `${ChallengerScoreUrls.challengerSetting}`,
    component: ChallengerScoreSettingsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'ticket',
    component: TicketComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'ticket/create-ticket',
    component: CreateTicketComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'ticket/ticketdetails/:id',
    component: TicketDetailsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'ticket/ticket-update/:id',
    component: TicketUpdateComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'others',
    component: OtherComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'others/:id',
    component: OtherComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'other/:index',
    component: OtherComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'other/inventory/add-item',
    component: AddInventoryItemComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'other/inventory/update-item/:id',
    component: AddInventoryItemComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'other/inventory/add-category',
    component: AddUpdateInventoryCategoryComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'other/inventory/update-Category/:id',
    component: AddUpdateInventoryCategoryComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'other/inventory/category-list',
    component: InventoryCategoryListComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },

  {
    path: 'setting',
    component: SettingComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'email-setup',
    component: EmailSetupComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'invoice-setup',
    component: InvoiceSetupComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-notification',
    component: ManageNotificationComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'hosted-payment-page-setting',
    component: HostedPaymentPageSettingMasterLayoutComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'setting/hosted-payment-link/configuration',
    component: HostedPaymentLinkConfigurationComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'setting/hosted-payment-link/configuration/:id',
    component: HostedPaymentLinkConfigurationComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'setting/hosted-payment-link',
    component: HostedPaymentLinkListComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'billing',
    component: BillingComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'billing/billing-profile/:invoiceId',
    component: BillingProfileComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'billing/billing-list',
    component: BillingListComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  { path: '**', component: PagenotfoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    TransactionsModule,
    UserManagementModule,
    CustomerScoreStatsModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
