import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatCalendarCellClassFunction,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SurchargeDialogComponent } from 'src/app/externalModules/paymentWidget/dialogs/surcharge-dialog/surcharge-dialog.component';
import { CardDetailsModel } from 'src/app/externalModules/paymentWidget/models/card-details.model';
import { PageSettingModel } from 'src/app/externalModules/paymentWidget/models/payment-page-customization.model';
import { EncryptDecryptService } from 'src/app/externalModules/paymentWidget/services/encrypt-decrypt.service';
import { PaymentWidgetCardService } from 'src/app/externalModules/paymentWidget/services/payment-widget-card.service';
import { NotificationMessages } from 'src/app/sharedModules/constants/notification-msg.const';
import { environment } from 'src/environments/environment';

interface DrowpDown {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-card-payment-info',
  templateUrl: './card-payment-info.component.html',
  styleUrls: ['./card-payment-info.component.scss'],
})
export class CardPaymentInfoComponent implements OnInit {
  cardType: string = '';

  @Input()
  cardDetailsFormGroup: FormGroup;

  cardDetailsModel: CardDetailsModel;

  isVisaCard: boolean = true;
  isMasterCard: boolean = false;
  isDiscoverCard: boolean = false;
  isAmericanExpressCard: boolean = false;

  minDate = new Date();
  maxDate = new Date();
  defaultScheduledDate = this.addDays(new Date(), 1);

  frequencies: DrowpDown[];

  @Output()
  cardDetailsFormGroupChange = new EventEmitter<any>();

  @Input()
  amount: any;

  @Input()
  uniqueToken: any;

  @Input()
  isRecurringDisabled: boolean;

  @Output()
  showLoaderEvent = new EventEmitter();

  @Input()
  pageSettingModel: PageSettingModel;

  @Output()
  surchargeDisAgree = new EventEmitter();

  constructor(
    private paymentWidgetCardService: PaymentWidgetCardService,
    private _toastrService: ToastrService,
    private dialog: MatDialog,
    private encryptDecryptService: EncryptDecryptService
  ) {}

  ngOnInit(): void {
    this.setFrequencyDropdown();
    
    this.isRecurringDisabled = this.isRecurringDisabled ? this.isRecurringDisabled : !this.pageSettingModel?.availRecurringForCard;
    this.cardDetailsFormGroup.controls['isRecurring'].setValue(false);

    this.cardDetailsFormGroup.valueChanges.subscribe((x) => {
      this.cardDetailsFormGroupChange.emit(this.cardDetailsFormGroup);
    });

    this.maxDate.setMonth(this.maxDate.getMonth() + 3);
  }

  recurringChecked(event) {
    this.cardDetailsFormGroup.controls['isRecurring'].setValue(event.checked);
  }

  setFrequencyDropdown(){
    let isDailyRecurringEnabled = `${environment.IS_DAILY_RECURRING_ENABLED}`;
    this.frequencies = (isDailyRecurringEnabled?.toLowerCase() == "true") ?
      [
        { value: 'DAILY', viewValue: 'Daily' },
        { value: 'WEEKLY', viewValue: 'Weekly' },
        { value: 'BIWEEKLY', viewValue: 'Bi-Weekly' },
        { value: 'MONTHLY', viewValue: 'Monthly' },
        { value: 'ANNUALLY', viewValue: 'Yearly' },
      ]
    :
      [
        { value: 'WEEKLY', viewValue: 'Weekly' },
        { value: 'BIWEEKLY', viewValue: 'Bi-Weekly' },
        { value: 'MONTHLY', viewValue: 'Monthly' },
        { value: 'ANNUALLY', viewValue: 'Yearly' },
      ];
  }

  endOfMonthCheck(event) {
    if (event.checked) {
      
      let currentDate = new Date();
      if((!this.cardDetailsFormGroup.controls['scheduledDate'].value) || 
      this.cardDetailsFormGroup.controls['frequency'].value == 'DAILY' ||
      this.cardDetailsFormGroup.controls['frequency'].value == 'WEEKLY' ||
      this.cardDetailsFormGroup.controls['frequency'].value == 'BIWEEKLY'){
        this.cardDetailsFormGroup.controls['frequency'].setValue('MONTHLY');
        this.onFrequencyChanged(this.cardDetailsFormGroup.controls['frequency'].value);
      } 
      currentDate = new Date(this.cardDetailsFormGroup.controls['scheduledDate'].value);

      let lastDateOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      this.cardDetailsFormGroup.controls['scheduledDate'].setValue(
        lastDateOfMonth
      );

    } else {
      this.onFrequencyChanged(this.cardDetailsFormGroup.controls['frequency'].value);
      this.cardDetailsFormGroup.controls['scheduledDate'].markAsUntouched();
      this.cardDetailsFormGroup.controls['scheduledDate'].markAsPristine();
    }
  }

  onFrequencyChanged(event: any){
    let isDailyRecurringEnabled = `${environment.IS_DAILY_RECURRING_ENABLED}`;
    
    this.defaultScheduledDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);

    if (event == "DAILY" && (isDailyRecurringEnabled?.toLowerCase() == "true")){
      this.defaultScheduledDate.setDate(this.defaultScheduledDate.getDate() + 1);
    } else if (event == "WEEKLY"){
      this.defaultScheduledDate.setDate(this.defaultScheduledDate.getDate() + 7);
    } else if (event == "BIWEEKLY"){
      this.defaultScheduledDate.setDate(this.defaultScheduledDate.getDate() + 14);
    } else if (event == "MONTHLY"){
      this.defaultScheduledDate.setMonth(this.defaultScheduledDate.getMonth() + 1);
    } else if (event == "ANNUALLY"){
      this.defaultScheduledDate.setMonth(this.defaultScheduledDate.getMonth() + 12);
      this.maxDate = new Date(this.defaultScheduledDate); 
      this.maxDate.setMonth(this.maxDate.getMonth() + 3);
    }
    
    if(event == "MONTHLY" || event == "ANNUALLY"){
      if(this.cardDetailsFormGroup.controls['isEndOfMonth'].value == true){
        let lastDateOfMonth = new Date(
          this.defaultScheduledDate.getFullYear(),
          this.defaultScheduledDate.getMonth() + 1,
          0
        );
  
        this.cardDetailsFormGroup.controls['scheduledDate'].setValue(
          lastDateOfMonth
        );
      } else{
        this.cardDetailsFormGroup.controls['scheduledDate'].setValue(this.defaultScheduledDate);
      }
    } else{
      this.cardDetailsFormGroup.controls['scheduledDate'].setValue(this.defaultScheduledDate);
    }
    if(this.cardDetailsFormGroup.controls['frequency'].value == 'DAILY' ||
        this.cardDetailsFormGroup.controls['frequency'].value == 'WEEKLY' ||
        this.cardDetailsFormGroup.controls['frequency'].value == 'BIWEEKLY'){
        this.cardDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
        this.cardDetailsFormGroup.controls['isEndOfMonth'].disable();
      } else{
        this.cardDetailsFormGroup.controls['isEndOfMonth'].enable();
      }
  }

  onscheduledDateChanged(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    let lastDateOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    );

    this.cardDetailsFormGroup.controls['scheduledDate'].setValue(selectedDate);
    if (selectedDate.getDate() == lastDateOfMonth.getDate()) {
      this.cardDetailsFormGroup.controls['isEndOfMonth'].setValue(true);
      
    } else {
      this.cardDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
    }
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateClass: MatCalendarCellClassFunction<Date> = (date: Date, view) => {
    if (
      view === 'month' &&
      this.maxDate.getDate() == date.getDate() &&
      this.maxDate.getMonth() == date.getMonth() &&
      this.maxDate.getFullYear() == date.getFullYear()
    ) {
      let lastDateOfMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );

      if (this.maxDate.getDate() < lastDateOfMonth.getDate()) {
        this.cardDetailsFormGroup.controls['isEndOfMonth'].disable();
        this.cardDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
      }
    } else if (this.maxDate.getMonth() != date.getMonth()) {
      this.cardDetailsFormGroup.controls['isEndOfMonth'].enable();
    }
    return '';
  };

  onCalendarClose() {
    this.setEOFForCalendar();
  }
  onCalendarCancel() {
    this.setEOFForCalendar();
  }

  setEOFForCalendar() {
    if (
      this.cardDetailsFormGroup.controls['scheduledDate'].value.getMonth() !=
      this.maxDate.getMonth()
    ) {
      this.cardDetailsFormGroup.controls['isEndOfMonth'].enable();

      let currentDate =
        this.cardDetailsFormGroup.controls['scheduledDate'].value;
      let lastDateOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      
      if (currentDate.getDate() == lastDateOfMonth.getDate()) {
        this.cardDetailsFormGroup.controls['isEndOfMonth'].setValue(true);
        this.cardDetailsFormGroup.controls['isEndOfMonth'].enable();
      } else{
        this.cardDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
      this.cardDetailsFormGroup.controls['isEndOfMonth'].disable();
      }

      if(this.cardDetailsFormGroup.controls['frequency'].value == 'DAILY' ||
        this.cardDetailsFormGroup.controls['frequency'].value == 'WEEKLY' ||
        this.cardDetailsFormGroup.controls['frequency'].value == 'BIWEEKLY'){
        this.cardDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
        this.cardDetailsFormGroup.controls['isEndOfMonth'].disable();
      } else{
        this.cardDetailsFormGroup.controls['isEndOfMonth'].enable();
      }

    } else {
      this.cardDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
      this.cardDetailsFormGroup.controls['isEndOfMonth'].disable();
    }
  }

  onFocusInEvent(event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.type = 'text';
  }

  onFocusOutEvent(event: any) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.type = 'password';
    var numberWihtoutSpace = event.target.value.replace(/\s/g, '');

    if (this.cardDetailsFormGroup.controls['cardNumber'].valid) {
      var data = {
        cardNumber:numberWihtoutSpace,
        amount: this.amount,
        uniqueToken: this.uniqueToken,
      };
      this.showLoaderEvent.emit(true);

      this.paymentWidgetCardService.LookUpBin(data).subscribe(
        (data) => {
          if(data.code == 400){
            this.showLoaderEvent.emit(false);
            this.cardDetailsFormGroup.controls['cardNumber'].reset();
            this._toastrService.error(data.errorMessage);
          } else 
          {
            this.showLoaderEvent.emit(false);
            this.cardType = this.getCardType(data?.data?.cardType);
            this.cardDetailsFormGroup.controls['cardType'].setValue(
              data?.data?.debit == 'N' ? 'Credit Card' : 'Debit Card'
            );
            if (data?.data?.debit == 'N') {
              this.openSurChargeDialog(data?.data);
            } else {
              this.cardDetailsFormGroup.controls['surchargeAmount'].setValue(0);
              this.cardDetailsFormGroup.controls['surchargePercent'].setValue(0);
              this.cardDetailsFormGroup.controls['agreesToSurcharge'].setValue(
                false
              );
              this.cardDetailsFormGroup.controls['totalAmount'].setValue(
                this.amount
              );
            }
          }
        },
        (error) => {
          this.showLoaderEvent.emit(false);
          this.cardDetailsFormGroup.controls['cardNumber'].reset();
          this._toastrService.error('Invalid Card Number');
        }
      );
    }
  }

  getCardType(cardType: string) {
    switch (cardType.toLowerCase()) {
      case 'mastercard':
      case 'discover':
      case 'unknown':
      case 'americanexpress':
      case 'visa':
        return cardType;
      default:
        return 'unknown';
    }
  }
  openSurChargeDialog(data: any) {
    const dialogRef = this.dialog.open(SurchargeDialogComponent, {
      disableClose: true,
      data: {
        title: 'Surcharge',
        showDisagree: !(this.pageSettingModel?.isPaymentOptionCard && this.pageSettingModel?.isPaymentOptionAch),  
        color: this.pageSettingModel.themeColor,
        mainMessage:
          'For Credit Card Payments a Surcharge of ' +
          (data?.surchargePercent ?? 0) +
          ' % is applicable.',
        subMessage:
          'Clicking on Accept button will apply surcharge fees on current total Amount.',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) {
        this.cardDetailsFormGroup.reset();
        this.cardDetailsFormGroup.markAsUntouched();
        this.cardDetailsFormGroup.markAsPristine();

        this.cardDetailsFormGroup.controls['isRecurring'].setValue(false);
        this.cardDetailsFormGroup.controls['surchargeAmount'].setValue(0);
        this.cardDetailsFormGroup.controls['surchargePercent'].setValue(0);
        this.cardDetailsFormGroup.controls['agreesToSurcharge'].setValue(false);
        this.cardDetailsFormGroup.controls['totalAmount'].setValue(this.amount);
        this.cardDetailsFormGroup.controls['installment'].setValue(null);
        this.surchargeDisAgree.emit(true);
      } else {
        this.cardDetailsFormGroup.controls['totalAmount'].setValue(data.total);
        this.cardDetailsFormGroup.controls['surchargeAmount'].setValue(
          data.surchargeAmount
        );
        this.cardDetailsFormGroup.controls['surchargePercent'].setValue(
          data.surchargePercent
        );
        this.cardDetailsFormGroup.controls['agreesToSurcharge'].setValue(true);
      }
    });
  }

  checkIfFrequencyDisabled(frequency: string) {
    return (
      this.cardDetailsFormGroup.controls['isEndOfMonth'].value &&
      (frequency == 'BIWEEKLY' || frequency == 'WEEKLY'  || frequency == 'DAILY')
    );
  }

  checkIfRecurring() {
    return this.cardDetailsFormGroup.controls['isRecurring'].value;
  }
}
