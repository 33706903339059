<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<mat-drawer-container>
  <mat-drawer #drawer position="end" class="mat-drawer-height">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      style="padding: 10%"
    >
      <div fxLayout="column" fxFlex="90%" class="heading-style">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div style="padding: 5%">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="filterFormGroup">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="16px"
            fxLayoutAlign="start stretch"
            class="form-group form-field-bottom"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select
                class="form-control"
                formControlName="type"
                name="type"
              >
                <mat-option *ngFor="let type of typeList" [value]="type.key"
                  >{{ type.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>State</mat-label>
              <mat-select
                class="form-control"
                formControlName="state"
                name="state"
              >
                <mat-option
                  *ngFor="let state of states"
                  [value]="state?.postalCode"
                  >{{ state?.state }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>City</mat-label>
              <input
                matInput
                class="form-control"
                formControlName="city"
                type="text"
                name="city"
                placholder="City"
                autocomplete="off"
              />
              <mat-error>
                {{ filterFormGroup.controls["city"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Zip</mat-label>
              <input
                matInput
                class="form-control"
                formControlName="zip"
                type="text"
                name="zip"
                placholder="Zip"
                autocomplete="off"
                [mask]="'00000'"
                [showMaskTyped]="true"
              />
              <mat-error>
                {{ filterFormGroup.controls["zip"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="applyAdvanceFilter()"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="user-header-bottom">
      <div
        class="user-header"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
      >
        <div fxLayout="column" fxLayoutGap="5px">
          <div
            fxLayout="column"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <div class="user-header-text">Users</div>
            <div class="user-sub-header-text">
              All Users related data can be managed from this page
            </div>
          </div>
        </div>

        <span class="spacer"></span>
        <div fxLayout="column" fxLayoutAlign="end end">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <!-- <button mat-stroked-button color="primary" class="button-style">
              IMPORT
            </button>
            &nbsp;&nbsp; -->
            <button
              *ngIf = "this.userRole != '1'"
              mat-raised-button
              color="primary"
              class="page-header-button-style"
              (click)="AddUser()"
            >
              ADD
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-data-table
      [columns]="columns"
      [data]="data"
      [isLoading]="isLoading"
      [isLoadingPaging]="isLoadingPaging"
      [page]="page"
      [sortBy]="sortBy"
      [noRecordMessage]="noRecordMessage"
      [actionDelete]="actionDelete"
      [showMoreMenu]="true"
      [allColumns]="allColumns"
      [selection]="selection"
      (actionDeleteEvent)="onDelete($event)"
      [deletedFilter]="deletedFilter"
      [actionRefresh]="actionRefresh"
      (actionRefreshEvent)="onRefresh($event)"
      (pageChangedEvent)="onPageChanged($event)"      
      (sortChangedEvent)="onSortChanged($event)"
      (onFilterEvent)="onFilter($event)"
      [isFilter]="false"
      [isExport]="false"
      (advanceFilterEvent)="openFilter(); drawer.toggle()"
      (deleteBasedOnSelectionEvent)="deleteBasedOnSelection($event)"
      (customerEditEvent)="customerEdit($event)"
      [filterText]="this.filter?.toString() ?? ''"
      [isActive]="false"
      [advanceFilterCount]="advanceFilterCount"
      [role]="this.userRole"
    >
    </app-data-table>
  </mat-drawer-content>
</mat-drawer-container>
