import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/sharedModules/models/user.model';
import { API_BASE_URL } from 'src/app/sharedModules/constants/url.const';
import { DBHelperService } from 'src/app/sharedModules/services/helper/db-helper.service';
import {
  AuthenticationServiceProxy,
  ChangePasswordRequest,
  ClientValidationRequest,
  ForgetPasswordRequest,
  ResendOtpRequest,
  SubmitForgetPasswordRequest,
  UpdateEulaApprovalRequest,
  ValidateOtpRequest,
} from 'src/app/sharedModules/services/login/login.proxies';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';
import { FormGroup } from '@angular/forms';
import { NotificationMessages } from 'src/app/sharedModules/constants/notification-msg.const';
import { UserManagementService } from 'src/app/featureModules/services/user-management.service';
import { addUserModel } from 'src/app/sharedModules/models/add-user.model';
import { UserProfileServiceProxy } from 'src/app/sharedModules/services/user-management/user-managament.proxies';
import { StorageService } from './storage.service';
import { DisplayWhiteLabellingClientSettings } from 'src/app/sharedModules/models/display-whitelabellingclient-settings.model';
import { FaviconService } from 'src/app/sharedModules/services/favicon/favicon.service';


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private validUser = false;
  public user: User;
  public userId: Number = 0;
  public userRoleId: Number = 0;
  public counter = 0;
  public isLocked: boolean = false;
  public authentication: AuthenticationServiceProxy;
  public clientValidationRequest: ClientValidationRequest;
  public userEmail: string;
  public errorMessage: string;
  public userCompany: string;
  public changePasswordRequest: ChangePasswordRequest;
  public forgetPasswordRequest: ForgetPasswordRequest;
  public submitForgetPasswordRequest: SubmitForgetPasswordRequest;
  public updateEulaApprovalRequest: UpdateEulaApprovalRequest;

  public displayWhiteLabellingClientSettings : DisplayWhiteLabellingClientSettings;
  // jwtHelper = new JwtHelperService();
  private _base: string;
  decodedToken: any;

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get currentUser() {
    return JSON.parse(localStorage.getItem('userProfile'));
  }

  get isValidUser() {
    return localStorage.getItem('validUser');
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private userManagementService: UserManagementService,
    private loginService: AuthenticationServiceProxy,
    private notifyService: NotificationService,
    private userProfileService: UserProfileServiceProxy,
    private storageService: StorageService,
    private favicon :FaviconService

  ) {
    const currentLogin = JSON.parse(
      localStorage.getItem('user') || '{}'
    ) as User;
    const currentToken = localStorage.getItem('token');
    if (currentLogin && currentToken) {
    }
  }

  validateOTP(data: ValidateOtpRequest, isRememberToken: boolean) {
    var isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;
    this.userCompany = localStorage.getItem('CompanyId');
    this.userEmail = JSON.parse(localStorage.getItem('userProfile'))['email'];
    var req: ValidateOtpRequest = new ValidateOtpRequest({
      otp: data.otp.toString(),
      userEmail: this.userEmail ? this.userEmail : '',
      tokenProvider: data.tokenProvider,
      isAdminLogin: data.isAdminLogin,
    });

    this.loginService.validateOtp(req).subscribe({
      next: (res) => {
        if (res.code.toString() == '200') {
          if (isRememberToken == true) {
            this.setRemember();
          }
          if (sessionStorage.getItem('isPasswordChangeRequired') == 'true') {
            this.router.navigate(['/forgotPassword']);
          } else {
            localStorage.setItem('validUser', 'true');
            if (isAdminLogin) {
              const currentPath = this.router.url;

              if (currentPath.includes('/csr')) location.reload();
              else this.router.navigate(['/csr']);
              localStorage.setItem('callCompanyList', 'true');
            } else {
              this.notifyService.showSuccess('Log in successfully');
              this.router.navigate(['/dashboard']);
            }
          }
        } else {
          this.notifyService.showError(res.data);
        }
      },
      error: (error) => {
        if (error.status == 401) {
          this.notifyService.showError(
            'Your current session times out. Please login again! '
          );
          localStorage.removeItem('userProfile');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('validUser');

          localStorage.removeItem('inventory');
          localStorage.removeItem('invoice');
          localStorage.removeItem('users');
          localStorage.removeItem('dashboard');
          localStorage.removeItem('reports');
          localStorage.removeItem('hostedLink');
          localStorage.removeItem('customers');
          localStorage.removeItem('instantBankAnalysis');
          localStorage.removeItem('statements');
          localStorage.removeItem('ticket');
          localStorage.removeItem('ach');
          localStorage.removeItem('cards');
          localStorage.removeItem('echeck');
          localStorage.removeItem('instantFund');
          localStorage.removeItem('callCompanyList');
          localStorage.removeItem('billing');
          isAdminLogin
            ? window.location.replace('csr')
            : window.location.replace('/');
        } else this.notifyService.showError(error.errorMessage);
      },
    });
  }
  refreshToken(
    companyId: any,
    isAdminLogin: boolean = false,
    redirectToDashboard = false
  ) {
    this.loginService
      .refreshToken({ companyId: companyId, isAdminLogin: isAdminLogin })
      .subscribe({
        next: (res) => {
          if (res.code.toString() == '200') {
            localStorage.setItem('accessToken', res.data['token']);
            if (res.data['themeSettings']){
              var foundSettingsData = res.data['themeSettings'];
              localStorage.setItem('ClientThemeSettings', foundSettingsData);
    
              this.displayWhiteLabellingClientSettings = new DisplayWhiteLabellingClientSettings();
              this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings = JSON.parse(res.data['themeSettings']);          
              localStorage.setItem('logoUrl', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['LogoUrl']);
              localStorage.setItem('logotext', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['LogoText']);
              localStorage.setItem('themecolor', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['ThemeColor']);
              localStorage.setItem('themenaccolor', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['ThemeNavColor']);
              localStorage.setItem('favicon', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['FavIcon']);
            }  

            //This is for csr login only
            if (redirectToDashboard) {
              this.notifyService.showSuccess('Log in successfully');
              this.router.navigate(['/dashboard']);
            }

            if (res.data['isPasswordChangeRequired'] == true) {
              this.notifyService.showError(
                'Your password is updated. Please sign in with updated password to access features.'
              );
              localStorage.removeItem('userProfile');
              localStorage.removeItem('accessToken');
              localStorage.removeItem('validUser');

              localStorage.removeItem('inventory');
              localStorage.removeItem('invoice');
              localStorage.removeItem('users');
              localStorage.removeItem('dashboard');
              localStorage.removeItem('reports');
              localStorage.removeItem('hostedLink');
              localStorage.removeItem('customers');
              localStorage.removeItem('instantBankAnalysis');
              localStorage.removeItem('statements');
              localStorage.removeItem('ticket');
              localStorage.removeItem('ach');
              localStorage.removeItem('cards');
              localStorage.removeItem('echeck');
              localStorage.removeItem('instantFund');
              localStorage.removeItem('callCompanyList');
              localStorage.removeItem('billing');
              isAdminLogin
                ? window.location.replace('csr')
                : window.location.replace('/');
            }
          } else {
            this.notifyService.showError(res.data);
          }
        },
        error: (error) => {},
      });
  }
  resendOTP(data: ResendOtpRequest) {
    var isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;
    this.loginService.resendOtp(data).subscribe({
      next: (res) => {
        if (res.code.toString() == '200') {
          this.notifyService.showSuccess('Resend code successfully');
        } else {
          this.notifyService.showError(res.data);
        }
      },
      error: (error) => {
        if (error.status == 401) {
          this.notifyService.showError(
            'Your current session times out. Please login again! '
          );
          localStorage.removeItem('userProfile');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('validUser');

          localStorage.removeItem('inventory');
          localStorage.removeItem('invoice');
          localStorage.removeItem('users');
          localStorage.removeItem('dashboard');
          localStorage.removeItem('reports');
          localStorage.removeItem('hostedLink');
          localStorage.removeItem('customers');
          localStorage.removeItem('instantBankAnalysis');
          localStorage.removeItem('statements');
          localStorage.removeItem('ticket');
          localStorage.removeItem('ach');
          localStorage.removeItem('cards');
          localStorage.removeItem('echeck');
          localStorage.removeItem('instantFund');
          localStorage.removeItem('callCompanyList');
          localStorage.removeItem('billing');
          isAdminLogin
            ? window.location.replace('csr')
            : window.location.replace('/');
        } else this.notifyService.showError(error.errorMessage);
      },
    });
  }

  doSignIn(
    company: string,
    email: string,
    password: string,
    signInForm: FormGroup,
    isAdminLogin: boolean = false
  ) {
    this.clientValidationRequest = new ClientValidationRequest();
    this.clientValidationRequest.password = password;
    this.clientValidationRequest.clientId = company;
    this.clientValidationRequest.login = email;
    this.clientValidationRequest.isAdminLogin = isAdminLogin;
    if (
      this.getJsonValue(
        company.toLowerCase() + '-' + email.toLowerCase() + '-Remember'
      )
    ) {
      this.clientValidationRequest.isRemember = true;
    } else {
      this.clientValidationRequest.isRemember = false;
    }

    if (!localStorage.getItem('tokenProvider')) {
      localStorage.setItem('tokenProvider', 'twilio');
    }

    this.clientValidationRequest.tokenProvider =
      localStorage.getItem('tokenProvider');
    const messageLocked: string = 'Account is locked';
    const messageInvalid: string = 'Login or Password does not match';
    const messageCompanyIdInvalid: string = 'Invalid Client Id';
    const messageDeactivatedCompany: string = 'Deactivated Company';
    const messageUnderwritingProgress: string =
      'Underwriting of the Company is in progress';

    this.loginService.getToken(this.clientValidationRequest).subscribe(
      (res) => {
        if (res.code == 200) {
          if (res.data['themeSettings']){
            var foundSettingsData = res.data['themeSettings'];
            localStorage.setItem('ClientThemeSettings', foundSettingsData);
  
            this.displayWhiteLabellingClientSettings = new DisplayWhiteLabellingClientSettings();
            this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings = JSON.parse(res.data['themeSettings']);          
            localStorage.setItem('logoUrl', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['LogoUrl']);
            localStorage.setItem('logotext', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['LogoText']);
            localStorage.setItem('themecolor', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['ThemeColor']);
            localStorage.setItem('themenaccolor', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['ThemeNavColor']);
            localStorage.setItem('favicon', this.displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['FavIcon']);
          }          
          localStorage.setItem('CompanyId', company);
          this.userCompany = company;
          this.userEmail = email;
          sessionStorage.setItem('userEmail', email);
          sessionStorage.setItem(
            'isPasswordChangeRequired',
            res.data['isPasswordChangeRequired']
          );
          sessionStorage.setItem(
            'currentPassword',
            password
          );

          localStorage.setItem('accessToken', res.data['accessToken']);
          this.userManagementService.getUserProfile(isAdminLogin);
          this.loggedIn.next(true);
          if (
            this.getJsonValue(
              company.toLowerCase() +
                '-' +
                this.userEmail.toLowerCase() +
                '-Remember'
            )
          ) {
            if (res.data['isPasswordChangeRequired'] == true) {
              this.router.navigate(['/forgotPassword'], {
                state: { isAdminLogin: isAdminLogin },
              });
              return;
            }

            var rememberDate = new Date(
              this.getJsonValue(
                company.toLowerCase() +
                  '-' +
                  this.userEmail.toLowerCase() +
                  '-Remember'
              )
            );
            rememberDate = new Date(
              rememberDate.setDate(rememberDate.getDate() + 60)
            );
            if (
              new Date(
                this.getJsonValue(
                  company.toLowerCase() +
                    '-' +
                    this.userEmail.toLowerCase() +
                    '-Remember'
                )
              ) < new Date(rememberDate.toString())
            ) {
              localStorage.setItem('validUser', 'true');

              if (isAdminLogin) {
                const currentPath = this.router.url;

                if (currentPath.includes('/csr')) location.reload();
                else this.router.navigate(['/csr']);

                localStorage.setItem('callCompanyList', 'true');
              } else {
                this.notifyService.showSuccess('Log in successfully');
                this.router.navigate(['/dashboard']);
              }
            } else {
              this.router.navigate(['/sendOTP']);
            }
          } else {
            this.router.navigate(['/sendOTP']);
          }
        } else {
          if (res.data == messageInvalid) {
            signInForm.setErrors({ unauthenticated: true });
          } else if (res.data.toString().includes(messageLocked)) {
            this.notifyService.showError(res.data.toString());
          } else if (res.data.toString().includes(messageCompanyIdInvalid)) {
            this.notifyService.showError(
              'Invalid Company Id entered. Please try again.'
            );
          } else if (res.data.toString().includes(messageDeactivatedCompany)) {
            this.notifyService.showError(
              'This company is deactivated, Please contact to Administrator.'
            );
          } else if (
            res.data.toString().includes(messageUnderwritingProgress)
          ) {
            this.notifyService.showError(
              'Underwriting of the Company is in progress, Please contact to Administrator.'
            );
          }
        }
        this.changeFavicon();
      },
      (error) => {
        this.notifyService.showError(error.errorMessage);
      }
    );
  }

  updateEulaApprovalDateAndStatus(
    status: boolean,
    company: string,
    email: string,
    password: string
  ) {
    this.updateEulaApprovalRequest = new UpdateEulaApprovalRequest();
    this.updateEulaApprovalRequest.status = status;
    this.updateEulaApprovalRequest.company = company;
    this.updateEulaApprovalRequest.email = email;
    this.updateEulaApprovalRequest.password = password;
    this.loginService
      .updateEulaApprovalStatus(this.updateEulaApprovalRequest)
      .subscribe(
        (res) => {
          if (res.code == 200) {
            this.notifyService.showSuccess('User Has Already Approved Eula !');
          } else if (res.code == 201) {
            this.notifyService.showSuccess('Eula Approved Successfully !');
          }
        },

        (err) => {
          this.notifyService.showError(err.errorMessage);
        }
      );
  }

  setRemember() {
    this.userCompany = localStorage.getItem('CompanyId');
    this.userEmail = JSON.parse(localStorage.getItem('userProfile'))['email'];
    this.setJsonValue(
      this.userCompany.toLowerCase() +
        '-' +
        this.userEmail.toLowerCase() +
        '-Remember',
      new Date().toString()
    );
  }

  changePassword(
    currentPassword: string,
    newPassword: string,
    isAdminLogin: boolean
  ) {
    this.changePasswordRequest = new ChangePasswordRequest();
    this.changePasswordRequest.currentPassword = currentPassword;
    this.changePasswordRequest.newPassword = newPassword;
    this.changePasswordRequest.isAdminLogin = isAdminLogin;

    this.loginService.changePassword(this.changePasswordRequest).subscribe(
      (res) => {
        if (res.code == 200) {
          this.notifyService.showSuccess(
            'Your password has been successfully changed'
          );

          localStorage.setItem('validUser', 'true');
          if (isAdminLogin) {
            const currentPath = this.router.url;

            if (currentPath.includes('/csr')) location.reload();
            else this.router.navigate(['/csr']);
            localStorage.setItem('callCompanyList', 'true');
          } else {
            this.notifyService.showSuccess('Log in successfully');
            this.router.navigate(['/dashboard']);
          }
        }
      },

      (err) => {
        this.notifyService.showError(err.errorMessage);
      }
    );
  }

  submitMail(companyId: string, email: string, isAdminLogin: boolean) {
    this.forgetPasswordRequest = new ForgetPasswordRequest();
    this.forgetPasswordRequest.clientId = companyId;
    this.forgetPasswordRequest.userEmail = email;
    this.forgetPasswordRequest.isAdminLogin = isAdminLogin;

    this.loginService
      .requestForgetPassword(this.forgetPasswordRequest)
      .subscribe(
        (res) => {
          if (res.code == 200) {
            this.notifyService.showSuccess(
              'Reset Password link has been shared on email! Please check your email.'
            );
            isAdminLogin
              ? this.router.navigate(['/csr'])
              : this.router.navigate(['']);
          }
        },

        (err) => {
          this.notifyService.showError('Invalid Company Id or Email');
        }
      );
  }
  submitForgetPassword(
    token: string,
    newPassword: string,
    isAdminLogin: boolean = false
  ) {
    this.submitForgetPasswordRequest = new SubmitForgetPasswordRequest();
    this.submitForgetPasswordRequest.linkToken = token;
    this.submitForgetPasswordRequest.newPassword = newPassword;
    this.submitForgetPasswordRequest.isAdminLogin = isAdminLogin;
    this.loginService
      .submitForgetPassword(this.submitForgetPasswordRequest)
      .subscribe(
        (res) => {
          if (res.code == 200) {
            this.notifyService.showSuccess(
              'Your password has been successfully changed'
            );
            this.router.navigate(['/forgotPasswordFinish']);
          }
        },

        (err) => {
          this.notifyService.showError(err.errorMessage);
        }
      );
  }
  setJsonValue(key: string, value: any) {
    this.storageService.secureStorage.setItem(key, value);
  }
  // Get the json value from local storage
  getJsonValue(key: string) {
    return this.storageService.secureStorage.getItem(key);
  }

  getCompanyListForUser() {
    this.loginService.getCompanyListForUser().subscribe(
      (res) => {
        if (res.code == 200) {
          return res.data;
        }
      },
      (err) => {
        this.notifyService.showError(err.errorMessage);
      }
    );
  }

  changeFavicon() {
    if(localStorage.getItem('favicon')){
    this.favicon.setFavicon(localStorage.getItem('favicon'));
    }

    if(localStorage.getItem('logotext')){
      this.favicon.setTitle(localStorage.getItem('logotext')  );
    }
  }
}
