import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { Observable } from 'rxjs';
import { CreateCustomerGroupModel } from 'src/app/sharedModules/models/create-customer-group.model';
import { UpdateCustomerGroupModel } from 'src/app/sharedModules/models/update-customer-group.model';
@Injectable({
  providedIn: 'root'
})
export class CustomerGroupService  extends CrudService<any, any>{

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}CustomerGroup`);
  }

  getAllActiveCustomerGroup(filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number, advanceFilters: string): Observable<any> {
      let baseUrl = this._base + "/GetAllActiveCustomerGroups?"
    baseUrl = sortBy !== undefined ? baseUrl + "sortBy=" + sortBy : baseUrl;
    baseUrl = baseUrl + "&sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;    
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;    
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }

 

  CreateCustomerGroup(model: CreateCustomerGroupModel): Observable<any> {
    return this._http.post<any>(this._base + "/createCustomerGroup", model);
  }

  UpdateCustomerGroup(model: UpdateCustomerGroupModel,customerGroupId): Observable<any> {
    return this._http.put<any>(this._base + "/UpdateCustomerGroup/"+customerGroupId, model);
  }

  getAllCustomerByGroupId(id): Observable<any> {
    return this._http.get<any>(this._base + "/getCustomerGroupById/" +id);
  }

  deleteCustomerGroupByGroupId(id): Observable<any> {
    return this._http.put<any>(this._base + "/deactivateCustomerGroup/" +id + "/false",null);
  }
}
