import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ChallengerScoreUrls } from 'src/app/blockModules/pages/challenger-score/challenger-score-settings-constants';
import { SharedService } from 'src/app/blockModules/pages/login/sharedService';
import { CommonService } from '../../services/common/common.service';
import { UserProfileServiceProxy } from '../../services/user-management/user-managament.proxies';
import { EulaDialogComponent } from '../dialogs/eula-dialog/eula-dialog.component';
import { LoginService } from 'src/app/blockModules/services/login.service';
import { AuthenticationServiceProxy } from '../../services/login/login.proxies';

@Component({
  selector: 'app-left-side-nav',
  templateUrl: './left-side-nav.component.html',
  styleUrls: ['./left-side-nav.component.scss'],
})
export class LeftSideNavComponent implements OnInit {
  loggedInUserName: string;
  userRole: string;
  notificationsCount: number = 5;
  loggedInUserInitial = '';
  clickedItem = '';
  navigationEventSubscriber: any;

  logoUrl: string;
  logotext: string;
  themeColor = '#000000';
  themenaccolor = '#000000';
  companyId: string;

  //General
  dashboard_const = 'dashboard';
  reports_const = 'reports';
  customers_const = 'customers';
  invoices_const = 'invoices';
  customer_score_stats_const = 'customerScoreStats';

  //Banking
  instantBankAnalysis_const = 'instantBankAnalysis';
  accountValidation_const = 'accountValidation';

  //Payment
  transactions_const = 'transactions';
  statements_const = 'statements';
  billing_const = 'billing';

  //Management
  companies_const = 'companies';
  finzeoUsers_const = 'finzeoUsers';
  roleManagement_const = 'roleManagement';

  challengerSetting_const = `/${ChallengerScoreUrls.challengerSetting}`;

  //Others
  holidays_const = 'holidays';
  audits_const = 'audits';
  processors_const = 'processors';
  settings_const = 'settings';
  Setting_const = 'setting';
  inventory_const = 'inventory';
  inventory_const1 = 'others';

  //Help
  ticket_const = 'ticket';

  ach_const = 'ach';
  creditDebit_const = 'CreditDebit';
  instantFunds_const = 'InstantFunds';

  userEmail: string;
  eulaApprovedDate: Date;
  isLoading: boolean = false;
  isAdminLogin: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => {
        return result.matches;
      })
    );
  user;
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private router: Router,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private _commonService: CommonService,
    private userProfileService: UserProfileServiceProxy,
    private authService: AuthenticationServiceProxy
  ) {}
  ngOnInit(): void {
    this.isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;

    this.companyId = localStorage.getItem('CompanyId');
    this.themeColor = localStorage.getItem('themecolor');
    this.logotext = localStorage.getItem('logotext');
    this.themenaccolor = localStorage.getItem('themenaccolor');
    this.logoUrl = localStorage.getItem('logoUrl');

    this.clickedItem = this.router.url.replace('/', '').split('/')[0];
    this.sharedService.newUser.subscribe((user) => {
      this.sharedService.users = user;
      this.user = this.sharedService.users;
      this.loggedInUserName =
        this.user['firstName'] + ' ' + this.user['lastName'];
      this.userRole = this.user['role'];

      switch (this.userRole?.toString()) {
        case '0':
          const logoText = localStorage.getItem('logotext');
          this.userRole = logoText ? `${logoText} Admin` : 'Admin';
          break;
        case '1':
          this.userRole = this.isAdminLogin 
            ? 'Customer Support Representative' 
            : 'Viewer';
          break;
      }

      this.loggedInUserInitial =
        this.user['firstName'].slice(0, 1) + this.user['lastName'].slice(0, 1);
    });
    this.navigationEventSubscriber =
      this._commonService.navigationEvent.subscribe((data) => {
        if (data) {
          this.clickedItem = data;
        }
      });
  }

  getIconSrc(iconName: string) {
    return 'assets/side-nav-icons/' + iconName + '.png';
  }

  getCompanyLogoSrc() {
    var logoURL = this.logoUrl;
    return logoURL;
  }

  checkCompanyPermission(): boolean {
    return !(this.companyId === 'RNO001' || this.companyId === 'TTA001');
  }
  reviewEula() {
    this.userProfileService
      .getUserProfile(this.isAdminLogin)
      .subscribe((res) => {
        if (res.data != null) {
          let item = res.data;
          this.eulaApprovedDate = item.eulaApprovalDate;
          const dialogRef = this.dialog.open(EulaDialogComponent, {
            height: '550px',
            width: '700px',
            data: {
              actionButton: true,
              viewOnly: true,
              eulaApprovedDate: this.eulaApprovedDate,
            },
            autoFocus: false,
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
            }
          });
        }
        this.isLoading = false;
      });
  }

  navigateToPage(pageName) {
    this.clickedItem = pageName;
    this.router.navigate([pageName]);
  }

  logout() {
    if (this.isAdminLogin || !this.getUserEmail()) {
      this.removeAccess();
      return;
    }

    this.performLogout();
  }

  private getUserEmail(): string | null {
    return sessionStorage.getItem('userEmail');
  }

  private performLogout() {
    const userEmail = this.getUserEmail();

    if (!userEmail) {
      this.removeAccess();
      return;
    }

    this.authService.doLogout({ emailId: userEmail }).subscribe(
      (res) => {
        if (res.code === 200) {
          this.removeAccess();
        }
      },
      () => this.removeAccess()
    );
  }

  removeAccess() {
    localStorage.removeItem('userProfile');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('validUser');
    localStorage.removeItem('userEmail');

    localStorage.removeItem('inventory');
    localStorage.removeItem('invoice');
    localStorage.removeItem('users');
    localStorage.removeItem('dashboard');
    localStorage.removeItem('reports');
    localStorage.removeItem('hostedLink');
    localStorage.removeItem('customers');
    localStorage.removeItem('instantBankAnalysis');
    localStorage.removeItem('statements');
    localStorage.removeItem('ticket');
    localStorage.removeItem('ach');
    localStorage.removeItem('cards');
    localStorage.removeItem('echeck');
    localStorage.removeItem('instantFund');
    localStorage.removeItem('callCompanyList');
    localStorage.removeItem('billing');

    this.isAdminLogin
      ? window.location.replace('csr')
      : window.location.replace('/');
    localStorage.removeItem('isAdminLogin');
  }

  navigateToProfile() {
    this.clickedItem = '';
    this.router.navigate(['/user-profile']);
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
    this._commonService.navigationEvent.emit('dashboard');
  }
}
