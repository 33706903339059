import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import creditCardType from 'credit-card-type';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { SearchBankService } from 'src/app/blockModules/services/search-bank.service';
import { CustomerBankDetailsService } from 'src/app/featureModules/services/customer-bank-details.service';
import { CustomerCardDetailsService } from 'src/app/featureModules/services/customer-card-details.service';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddCustomerBankDetailsModel } from 'src/app/sharedModules/models/add-customer-bank-details.model';
import { AddCustomerCardDetailsModel } from 'src/app/sharedModules/models/add-customer-card-details.model';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { SortChanged } from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { findInvalidControls } from 'src/app/sharedModules/utils/dev-utils';

@Component({
  selector: 'app-add-customer-from-customergroup',
  templateUrl: './add-customer-from-customergroup.component.html',
  styleUrls: ['./add-customer-from-customergroup.component.scss']
})

export class AddCustomerFromCustomergroupComponent implements OnInit {
  isLoading: boolean = false;
  isCompany: boolean = false;
  separateDialCode: boolean = true;
  isBasicDetailsFilled: boolean = false;
  isBankDetailsFilled: boolean = false;
  isCardDetailsFilled: boolean = false;
  isAddCustomer: boolean = true;
  isAddCustomerCard: boolean = true;
  isAddCustomerBank: boolean = true;
  isSearchLoading: boolean = false;
  isShowNoFound: boolean = false;
  isBankReset: boolean = true;
  isCardReset: boolean = true;
  isCardAddressSameAsBasicAddress: boolean = false;
  isTabDisabled: boolean = true;

  customerId: string;
  bankAndCardId: string;
  loadMessage: string = 'Please wait..';
  labelCustomer: string = "Add New Customer";
  lableAddUpdateBank: string = "ADD ACCOUNT"
  lableAddUpdateCard: string = "ADD ACCOUNT"
  maxLength: string = '15';
  datatableMargin: string = "0px";

  states: any;
  phoneNumber: any;
  bankList: any;
  debitCreditCardsList: any;
  userRole: any;
  bankNameList: any[] = [];
  phonehint: any = '(201) 555-0123';
  cardImageName: any = 'assets/grid-icons/default-card.png';
  selectedIndex: any = 0;

  minLengthTerm: number = 3;
  previousTabIndex: number = 0;
  currentTabIndex: number = 0;

  basicDetailsFormGroup: FormGroup;
  bankDetailsFormGroup: FormGroup;
  cardDetailsFormGroup: FormGroup;
  mobileNumberControl: FormControl;

  customerModel: AddCustomerModel;
  basicDetailsModel: AddCustomerModel;
  bankDetailsModel: AddCustomerBankDetailsModel;
  cardDetailsModel: AddCustomerCardDetailsModel;
  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;

  customerTypeList = [
    { key: false, value: 'Individual' },
    { key: true, value: 'Company' },
  ];

  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  accountType = [
    { value: 'saving', display: 'Saving' },
    { value: 'checking', display: 'Checking' },
  ];

  sortByBank: SortChanged = {
    active: 'bankName',
    direction: 'asc',
  };

  sortByCard: SortChanged = {
    active: 'nameOnCard',
    direction: 'asc',
  };

  bankColumns: TableColumn[] = [
    { displayName: 'BANK NAME', name: 'bankName', width: '30%' },
    { displayName: 'ACCT NUMBER', name: 'bankAccountNumber', width: '25%' },
    { displayName: 'RTG NUMBER', name: 'routingNumber', width: '20%' },
    { displayName: 'ACCOUNT TYPE', name: 'accountType', width: '20%' },
    { displayName: 'STATUS', name: 'isActive', width: '15%' },
    { displayName: 'PRIMARY', name: 'isPrimary', width: '15%' },
    { displayName: '', name: 'bankAction', width: '10%' }
  ];

  cardColumns: TableColumn[] = [
    { displayName: 'NAME ON CARD', name: 'nameOnCard', width: '30%' },
    { displayName: 'CARD NUMBER', name: 'cardNumber', width: '35%' },
    { displayName: 'ADDRESS', name: 'address', width: '30%' },
    { displayName: 'EXP', name: 'cardExpDate', width: '15%' },
    { displayName: 'STATUS', name: 'isActive', width: '15%' },
    { displayName: 'PRIMARY', name: 'isPrimary', width: '15%' },
    { displayName: '', name: 'cardAction', width: '10%' }
  ];

  bankColumnsSummary: TableColumn[] = [
    { displayName: 'BANK NAME', name: 'bankName', width: '30%' },
    { displayName: 'ACCT NUMBER', name: 'bankAccountNumber', width: '25%' },
    { displayName: 'RTG NUMBER', name: 'routingNumber', width: '20%' },
    { displayName: 'ACCOUNT TYPE', name: 'accountType', width: '20%' },
    { displayName: 'STATUS', name: 'isActive', width: '15%' },
    { displayName: 'PRIMARY', name: 'isPrimary', width: '15%' }
  ];

  cardColumnsSummary: TableColumn[] = [
    { displayName: 'NAME ON CARD', name: 'nameOnCard', width: '30%' },
    { displayName: 'CARD NUMBER', name: 'cardNumber', width: '35%' },
    { displayName: 'ADDRESS', name: 'address', width: '30%' },
    { displayName: 'EXP', name: 'cardExpDate', width: '15%' },
    { displayName: 'STATUS', name: 'isActive', width: '15%' },
    { displayName: 'PRIMARY', name: 'isPrimary', width: '15%' }
  ];

  yearList = [];
  monthList = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  @Input()
  noRecordMessage: string;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  constructor(private customerDialogRef: MatDialogRef<AddCustomerFromCustomergroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private _customerService: CustomerService,
    private _customerBankDetailsService: CustomerBankDetailsService,
    private _customerCardDetailsService: CustomerCardDetailsService,
    private searchBankService: SearchBankService,
    private _invoiceService: InvoiceService,
    private _toastrService: ToastrService,
    private confirmationDialog: MatDialog
  ) {
    this.customerId = data.customerId;
  }

  ngOnInit(): void {
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    this.loadData();

    this.basicDetailsModel = new AddCustomerModel();
    this.basicDetailsFormGroup = this.formBuilder.formGroup(this.basicDetailsModel);

    this.bankDetailsModel = new AddCustomerBankDetailsModel();
    this.bankDetailsFormGroup = this.formBuilder.formGroup(this.bankDetailsModel);

    this.bankDetailsFormGroup.valueChanges.subscribe((x) => {
      if (x.bankName || x.bankAccountNumber || x.accountType || x.routingNumber)
        this.isBankReset = false;
    });

    this.cardDetailsModel = new AddCustomerCardDetailsModel();
    this.cardDetailsFormGroup = this.formBuilder.formGroup(
      this.cardDetailsModel
    );

    this.getYearList();

    this.cardDetailsFormGroup.valueChanges.subscribe((x) => {
      if (
        x.nameOnCard ||
        x.cardNumber ||
        x.expMonth ||
        x.expYear ||
        x.address ||
        x.city ||
        x.state ||
        x.zip
      )
        this.isCardReset = false;
    });

    this.bindBanks();

    this.mobileNumberControl = new FormControl('');

    this.selectedIndex = this.customerId ? 3 : 0;
  }

  closePopup(tabGroup: MatTabGroup) {

    let previousIndex = tabGroup.selectedIndex;

    let customer = {
      customerId: this.customerModel?.customerId,
      firstName: this.customerModel?.firstName,
      lastName: this.customerModel?.lastName,
      email: this.customerModel?.email,
      hasBankAccount: this.bankList?.length > 0,
      hasCard: this.debitCreditCardsList?.length > 0,
    };

    if (!this.checkingValidation(previousIndex)) {
      this.customerDialogRef.close(customer);
      return;
    }

    let matLabel =
      previousIndex == 0
        ? 'Basic Details'
        : previousIndex == 1
          ? 'Bank Details'
          : previousIndex == 2
            ? 'Card Details'
            : '';

    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      height: '180px',
      data: {
        buttonName: 'YES',
        buttonCancel: 'NO',
        successMessage: "You have unsaved changes on '" + matLabel + "' page.",
        subSuccessMessage: "Are you sure you want to cancel and exit the screen?",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) {
        tabGroup.selectedIndex = previousIndex;
      } else {
        this.customerDialogRef.close(customer);
      }
    });
  }

  onCustomerTypeChange(event: MatSelectChange): void {
    this.isCompany = event.value;
    this.basicDetailsFormGroup.controls['firstName'].setValue('');
    this.basicDetailsFormGroup.controls['lastName'].setValue('');
  }

  changePhone(event: any) {
    if (
      event.iso2.toLowerCase() == this.customerModel?.isoCode?.toLowerCase()
    ) {
      this.basicDetailsFormGroup.controls['phone'].setValue(
        this.customerModel.mobileNumber
      );
    } else {
      this.basicDetailsFormGroup.controls['phone'].setValue('');
    }
    this.phonehint = event.placeHolder;
  }

  loadData() {
    if (this.customerId) {
      this.getCustomerById();
      this.getAllBanks(this.customerId);
      this.getAllCards(this.customerId, false);
    }

    if (
      this._commonService._constantData == undefined ||
      this._commonService._constantData == null
    ) {
      this._commonService.getJSON('constants');
    }
    this.states = this._commonService._constantData.states;
  }

  addCustomerBasicDetails(tabGroup: MatTabGroup, currentTabIndex: number) {
    this.isLoading = true;

    findInvalidControls(this.basicDetailsFormGroup);

    if (this.isAddCustomer) {

      this.basicDetailsFormGroup.controls['isCompany'].setValue(this.isCompany);

      if (this.basicDetailsFormGroup.controls['phone'].value) {
        this.basicDetailsFormGroup.controls['mobileNumber'].setValue(
          this.basicDetailsFormGroup.controls['phone'].value.e164Number
        );
        this.basicDetailsFormGroup.controls['isoCode'].setValue(
          this.basicDetailsFormGroup.controls['phone'].value.countryCode
        );
      }

      if (this.basicDetailsFormGroup.valid) {

        let customerData: AddCustomerModel = this.basicDetailsFormGroup.value;

        this._customerService.AddCustomerBasicDetails(customerData).subscribe(
          (response) => {
            if (response.code == 201 && response.data) {
              this.customerModel = response.data;
              this.customerModel.customerId = response.data.id;
              this._invoiceService.cName = response.data.firstName + " " + response.data.lastName;
              this._invoiceService.cId = response.data.id;
              this.customerId = response.data.id;
              this.getCustomerById();
              this.bankList = [];
              this.debitCreditCardsList = [];
              this._toastrService.success('Customer added successfully!');
              this.isLoading = false;

              this.basicDetailsFormGroup.markAsPristine();
              this.basicDetailsFormGroup.markAsUntouched();
              tabGroup.selectedIndex = currentTabIndex + 1;
            } else if (response.code == 400) {
              this.isLoading = false;
              this._toastrService.error(response.errorMessage);
            } else {
              this.isLoading = false;
            }
          },
          (error) => {
            if (
              error.error.message != null ||
              error.error.message != undefined
            ) {
              this._toastrService.warning(error.error.message);
            } else {
              this._toastrService.warning(error.error.content);
            }
            this.isLoading = false;
          }
        );
      } else {
        this.basicDetailsFormGroup.markAllAsTouched();
        this._toastrService.error('Please fill all the required details!');
        this.isLoading = false;
      }
    } else {
      if (this.basicDetailsFormGroup.controls['phone'].value) {
        this.basicDetailsFormGroup.controls['mobileNumber'].setValue(
          this.basicDetailsFormGroup.controls['phone'].value.e164Number
        );
        this.basicDetailsFormGroup.controls['isoCode'].setValue(
          this.basicDetailsFormGroup.controls['phone'].value.countryCode
        );
      }
      if (this.basicDetailsFormGroup.touched && this.basicDetailsFormGroup.dirty
      ) {
        if (this.basicDetailsFormGroup.valid) {
          if (this.basicDetailsFormGroup.controls['type'].value) {
            this.basicDetailsFormGroup.controls['lastName'].setValue('');
          }
          let customerData: AddCustomerModel = this.basicDetailsFormGroup.value;

          this._customerService
            .EditCustomerBasicDetails(this.customerId, customerData)
            .subscribe(
              (response) => {
                if (response.code == 200 && response.data) {
                  this.customerModel = response.data;
                  this.getCustomerById();
                  this.getAllBanks(this.customerId);
                  this.getAllCards(this.customerId, false);
                  this.isLoading = false;
                  this._toastrService.success('Customer updated successfully!');

                  this.basicDetailsFormGroup.markAsPristine();
                  this.basicDetailsFormGroup.markAsUntouched();
                  tabGroup.selectedIndex = currentTabIndex + 1;
                } else {
                  this.isLoading = false;
                  this._toastrService.error(response.errorMessage);
                }
              },
              (error) => {
                this.isLoading = false;
              }
            );
        } else {
          this.basicDetailsFormGroup.markAllAsTouched();
          this._toastrService.error('Please fill all the required details!');
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
        tabGroup.selectedIndex = currentTabIndex + 1;
      }
    }
  }

  getCustomerById() {
    this.isLoading = true;
    this.separateDialCode = true;
    this._customerService.getCustomerById(this.customerId).subscribe((response) => {
      this.isLoading = false;
      if (response.data != null) {
        this.separateDialCode = false;
        this.isAddCustomer = false;
        this.isBasicDetailsFilled = true;
        this.customerModel = response.data;
        this.labelCustomer = `${this.customerModel?.firstName ?? ''} ${this.customerModel?.lastName ?? ''}`.trim();
        this.basicDetailsFormGroup.patchValue(this.customerModel);
        this.phoneNumber = this.customerModel.mobileNumber;
        this.basicDetailsFormGroup.controls['phone'].setValue(this.phoneNumber);
        this.mobileNumberControl.setValue(this.phoneNumber);
        this.mobileNumberControl.disable();
        this.phonehint = null;
        this.isTabDisabled = false;
        this.isCompany = this.customerModel.type;
        this.basicDetailsFormGroup.markAsPristine();
        this.basicDetailsFormGroup.markAsUntouched();
      }
    }),
      (error) => {
        this.isLoading = false;
      };
  }

  getAllBanks(cutotmerId: any) {
    this.isLoading = true;
    this._customerBankDetailsService
      .GetAllBankAccounts(cutotmerId, false)
      .subscribe(
        (response) => {

          this.bankList = response.data && response.data.listOfBankAccountsToReturn ? response.data.listOfBankAccountsToReturn.map((bank: any) => ({
            ...bank,
            isActive: bank.status
          })) : [];

          this.isBankDetailsFilled = this.bankList.length > 0;
          this.isLoading = false;
        },
        (error) => {
          if (error.error.message != null || error.error.message != undefined) {
            this._toastrService.warning(error.error.message);
          } else {
            this._toastrService.warning(error.error.content);
          }
          this.isLoading = false;
        }
      );
  }

  getAllCards(cutotmerId: any, onlyActive: boolean) {
    this.isLoading = true;
    this._customerCardDetailsService
      .GetAllCards(cutotmerId, onlyActive)
      .subscribe(
        (response) => {

          this.debitCreditCardsList = response.data ? response.data.map((card: any) => ({
            ...card,
            isActive: card.status
          })) : [];

          this.isCardDetailsFilled = this.debitCreditCardsList.length > 0;
          this.isLoading = false;
        },
        (error) => {
          if (error.error.message != null || error.error.message != undefined) {
            this._toastrService.warning(error.error.message);
          } else {
            this._toastrService.warning(error.error.content);
          }
          this.isLoading = false;
        }
      );
  }

  bindBanks() {
    this.isShowNoFound = false;
    this.bankDetailsFormGroup
      .get('bankName')
      .valueChanges.pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return res !== 'N/A' && res !== null && res.length >= this.minLengthTerm;
          } else {
            this.bankNameList = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.bankNameList = [];
          this.isSearchLoading = true;
        }),
        switchMap((value) =>
          this.searchBankService.SearchByBankName(value).pipe(
            finalize(() => {
              this.isSearchLoading = false;
            })
          )
        )
      )
      .subscribe(
        (data: any) => {
          if (data.data == undefined) {
            this.isSearchLoading = false;
            this.isShowNoFound = true;
            this.bankNameList = [];
          } else {
            this.isShowNoFound = false;
            this.bankNameList = data.data;
          }
        },
        (error) => {
          this.isSearchLoading = false;
        }
      );
  }

  clearSelection() {
    this.bankNameList = [];
    this.bankDetailsFormGroup.get('bankName').setValue(null);
  }

  addCustomerBankDetails(tabGroup: MatTabGroup, currentTabIndex: number) {
    this.isLoading = true;

    if (this.bankNameList?.length) {
      let selectedBank = this.bankNameList.filter(
        (obj) => obj === this.bankDetailsFormGroup.get('bankName').value
      );
      if (!selectedBank?.length) {
        this.bankNameList = [];
        this.bankDetailsFormGroup.get('bankName').setValue(null);
      }
    } else {
      this.bankNameList = [];
      this.bankDetailsFormGroup.get('bankName').setValue(null);
    }

    if (this.isAddCustomerBank) {
      this.bankDetailsFormGroup.controls['customerId'].setValue(
        this.customerId
      );
      if (this.bankDetailsFormGroup.valid) {
        this._customerBankDetailsService
          .AddCustomerBankDetails(this.bankDetailsFormGroup.value)
          .subscribe(
            (response) => {
              if (response.code == 201) {
                this.bankDetailsFormGroup.reset();
                this.bankDetailsFormGroup.markAsPristine();
                this.bankDetailsFormGroup.markAsUntouched();
                this.bankNameList = [];
                this._toastrService.success('Bank Details added successfully!');
                this.getAllBanks(this.customerId);
                tabGroup.selectedIndex = currentTabIndex + 1;
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this._toastrService.warning(response.errorMessage);
              }
            },
            (error) => {
              if (
                error.error.message != null ||
                error.error.message != undefined
              ) {
                this._toastrService.warning(error.error.message);
              } else {
                this._toastrService.warning(error.error.content);
              }
              this.isLoading = false;
            }
          );
      } else {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }
    } else {
      if (this.bankDetailsFormGroup.valid) {
        this._customerBankDetailsService
          .updateCustomerBank(
            this.bankDetailsFormGroup.value.customerBankAccountId,
            this.bankDetailsFormGroup.value
          )
          .subscribe(
            (response) => {
              if (response.code == 200) {
                this._toastrService.success(
                  'Bank details updated successfully!'
                );
                this.bankAndCardId = null;
                this.resetBankForm();
                this.getAllBanks(this.customerId);
                tabGroup.selectedIndex = currentTabIndex + 1;
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this._toastrService.warning(response.errorMessage);
              }
            },
            (error) => {
              if (
                error.error.message != null ||
                error.error.message != undefined
              ) {
                this._toastrService.warning(error.error.message);
              } else {
                this._toastrService.warning(error.error.content);
              }
              this.isLoading = false;
            }
          );
      } else {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }
    }
  }

  resetBankForm() {
    this.bankNameList = [];
    this.bankAndCardId = null;
    this.isBankReset = true;
    this.isAddCustomerBank = true;
    this.lableAddUpdateBank = "ADD ACCOUNT";
    this.bankDetailsFormGroup.reset();
    this.bankDetailsModel = new AddCustomerBankDetailsModel();
    Object.keys(this.bankDetailsFormGroup.controls).forEach((key) => {
      const control = this.bankDetailsFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  saveAndNext(tabGroup: MatTabGroup, currentTabIndex: number) {
    tabGroup.selectedIndex = currentTabIndex + 1;
  }

  onBankSortChanged(event: SortChanged) {

    if (this.sortByBank.active !== event.active || this.sortByBank.direction !== event.direction) {
      this.sortByBank = event;

      if (this.bankList) {
        const { active, direction } = this.sortByBank;

        const sortedList = [...this.bankList].sort((a, b) => {
          const valueA = a[active];
          const valueB = b[active];

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          }
          else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return direction === 'asc' ? valueA - valueB : valueB - valueA;
          }
          else {
            const strA = String(valueA);
            const strB = String(valueB);
            return direction === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
          }
        });
        this.bankList = sortedList;
      }
    }
  }

  bankEdit(customerBankAccountId: any) {
    if (customerBankAccountId && this.bankList) {
      let selectedBank = this.bankList.filter(
        (obj: any) => obj.customerBankAccountId == customerBankAccountId
      );
      if (selectedBank && selectedBank[0]) {
        this.isAddCustomerBank = false;
        this.isBankReset = false;
        this.isBankDetailsFilled = true;
        this.lableAddUpdateBank = "UPDATE ACCOUNT";
        this.bankDetailsModel = selectedBank[0];
        this.bankDetailsFormGroup.patchValue(this.bankDetailsModel);
      }
    }
  }

  getCardImage() {
    this.cardImageName = 'assets/grid-icons/default-card.png';
    if (this.cardDetailsFormGroup.controls['cardNumber'].value) {
      var visaCards = creditCardType(
        this.cardDetailsFormGroup.controls['cardNumber'].value
      );
      if (visaCards && visaCards[0] && visaCards[0].type)
        this.cardImageName = 'assets/grid-icons/' + visaCards[0].type + '.png';
    }
    return this.cardImageName;
  }

  getYearList() {
    var currentYear = new Date().getFullYear();
    for (var i = 0; i < 30; i++) {
      this.yearList.push(currentYear + i);
    }
  }

  resetCardForm() {
    this.cardImageName = 'assets/grid-icons/default-card.png';
    this.bankAndCardId = null;
    this.isCardReset = true;
    this.cardDetailsFormGroup.reset();
    this.cardDetailsModel = new AddCustomerCardDetailsModel();
    this.isAddCustomerCard = true;
    Object.keys(this.cardDetailsFormGroup.controls).forEach((key) => {
      const control = this.cardDetailsFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  updateCardAddress(isCardAddressSameAsBasicAddress: boolean) {
    const basicDetailsAddress1 = this.basicDetailsFormGroup.get('address1');
    const basicDetailsAddress2 = this.basicDetailsFormGroup.get('address2');
    const basicDetailsCity = this.basicDetailsFormGroup.get('city');
    const basicDetailsState = this.basicDetailsFormGroup.get('state');
    const basicDetailsZip = this.basicDetailsFormGroup.get('zip');

    const cardAddressControl = this.cardDetailsFormGroup.controls['address'];
    const cardCityControl = this.cardDetailsFormGroup.controls['city'];
    const cardStateControl = this.cardDetailsFormGroup.controls['state'];
    const cardZipControl = this.cardDetailsFormGroup.controls['zip'];

    const setCardAddress = (address: string | null, city: string | null, state: string | null, zip: string | null) => {
      cardAddressControl.setValue(address);
      cardCityControl.setValue(city);
      cardStateControl.setValue(state);
      cardZipControl.setValue(zip);
    };

    if (isCardAddressSameAsBasicAddress) {
      const address = `${basicDetailsAddress1?.value || ''} ${basicDetailsAddress2?.value || ''}`;
      setCardAddress(address, basicDetailsCity?.value || null, basicDetailsState?.value || null, basicDetailsZip?.value || null);
    } else {
      setCardAddress(null, null, null, null);
    }
  }

  addCustomerCardDetails(tabGroup: MatTabGroup, currentTabIndex: number) {
    this.isLoading = true;
    let expiryMonth = this.cardDetailsFormGroup.controls['expMonth'].value;
    let expiryYear = this.cardDetailsFormGroup.controls['expYear'].value;
    let currentMonth = new Date().getMonth() + 1;
    let currentYear = new Date().getFullYear();
    if (parseInt(expiryMonth) < currentMonth && currentYear == expiryYear) {
      this._toastrService.warning('Invalid Exp Month and Exp Year selected.');
      this.isLoading = false;
      return;
    }
    if (this.isAddCustomerCard) {
      this.cardDetailsFormGroup.controls['customerId'].setValue(
        this.customerId
      );
      if (this.cardDetailsFormGroup.valid) {
        this._customerCardDetailsService
          .AddCustomerCardDetails(this.cardDetailsFormGroup.value)
          .subscribe(
            (data) => {
              if (data.code == 201) {
                this.cardDetailsFormGroup.reset();
                this.cardDetailsFormGroup.markAsPristine();
                this.cardDetailsFormGroup.markAsUntouched();
                this.cardImageName = 'assets/grid-icons/default-card.png';
                this._toastrService.success('Card details added successfully!');
                this.getAllCards(this.customerId, false);
                tabGroup.selectedIndex = currentTabIndex + 1;
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this._toastrService.warning(data.errorMessage);
              }
            },
            (error) => {
              if (
                error.error.message != null ||
                error.error.message != undefined
              ) {
                this._toastrService.warning(error.error.message);
              } else {
                this._toastrService.warning(error.error.content);
              }
              this.isLoading = false;
            }
          );
      } else {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }
    } else {
      if (this.cardDetailsFormGroup.valid) {
        this._customerCardDetailsService
          .updateCustomerCard(
            this.cardDetailsFormGroup.value.customerCardId,
            this.cardDetailsFormGroup.value
          )
          .subscribe(
            (data) => {
              if (data.code == 200) {
                this.bankAndCardId = null;
                this.resetCardForm();
                this.cardImageName = 'assets/grid-icons/default-card.png';
                this._toastrService.success(
                  'Card details updated successfully!'
                );
                this.getAllCards(this.customerId, false);
                tabGroup.selectedIndex = currentTabIndex + 1;
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this._toastrService.warning(data.errorMessage);
              }
            },
            (error) => {
              this.isLoading = false;
            }
          );
      } else {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }
    }
  }

  onCardSortChanged(event: SortChanged) {

    if (this.sortByCard.active !== event.active || this.sortByCard.direction !== event.direction) {
      this.sortByCard = event;

      if (this.debitCreditCardsList) {
        const { active, direction } = this.sortByCard;

        const sortedList = [...this.debitCreditCardsList].sort((a, b) => {
          const valueA = a[active];
          const valueB = b[active];

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          }
          else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return direction === 'asc' ? valueA - valueB : valueB - valueA;
          }
          else {
            const strA = String(valueA);
            const strB = String(valueB);
            return direction === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
          }
        });
        this.debitCreditCardsList = sortedList;
      }
    }
  }

  cardEdit(customerCardId: any) {
    if (customerCardId && this.debitCreditCardsList) {
      let selectedCard = this.debitCreditCardsList.filter(
        (obj: any) => obj.customerCardId == customerCardId
      );
      if (selectedCard && selectedCard[0]) {
        this.isAddCustomerCard = false;
        this.isCardReset = false;
        this.isCardDetailsFilled = true;
        this.lableAddUpdateCard = "UPDATE ACCOUNT";
        this.cardDetailsModel = selectedCard[0];
        this.cardDetailsFormGroup.patchValue(this.cardDetailsModel);
        this.getCardImage();
        this.cardDetailsModel.expMonth = this.cardDetailsModel.expMonth.toString();
        if (this.cardDetailsModel.expMonth.length < 2) {
          this.cardDetailsModel.expMonth = '0' + this.cardDetailsModel.expMonth;
          this.cardDetailsFormGroup.controls['expMonth'].setValue(this.cardDetailsModel.expMonth);
        }
      }
    }
  }

  selectedTabChange(event: MatTabChangeEvent, tabGroup: MatTabGroup) {
    this.previousTabIndex = this.currentTabIndex;
    this.currentTabIndex = event?.index;
    switch (event.index) {
      case 0:
        if (this.checkingValidation(this.previousTabIndex))
          this.tabChangeConfirmation(this.previousTabIndex, tabGroup);
        break;
      case 1:
        if (this.checkingValidation(this.previousTabIndex))
          this.tabChangeConfirmation(this.previousTabIndex, tabGroup);
        break;
      case 2:
        if (this.checkingValidation(this.previousTabIndex))
          this.tabChangeConfirmation(this.previousTabIndex, tabGroup);
        break;
      case 3:
        if (this.checkingValidation(this.previousTabIndex))
          this.tabChangeConfirmation(this.previousTabIndex, tabGroup);
    }
  }

  navigateToSelectedTab(tabGroup: MatTabGroup, tabIndex: number) {
    tabGroup.selectedIndex = tabIndex;
  }

  checkingValidation(previousIndex: number) {
    let flag = false;
    switch (previousIndex) {
      case 0:
        flag =
          this.basicDetailsFormGroup.touched &&
          this.basicDetailsFormGroup.dirty;
        break;
      case 1:
        flag =
          this.bankDetailsFormGroup.touched && this.bankDetailsFormGroup.dirty;
        break;
      case 2:
        flag =
          this.cardDetailsFormGroup.touched && this.cardDetailsFormGroup.dirty;
        break;
    }
    return flag;
  }

  tabChangeConfirmation(previousIndex: number, tabGroup: MatTabGroup) {
    let matLabel =
      previousIndex == 0
        ? 'Basic Details'
        : previousIndex == 1
          ? 'Bank Details'
          : previousIndex == 2
            ? 'Card Details'
            : '';

    const dialogRef = this.confirmationDialog.open(ConfirmationDialogComponent, {
      height: '180px',
      data: {
        buttonName: 'YES',
        buttonCancel: 'NO',
        successMessage: "You have unsaved changes on '" + matLabel + "' page.",
        subSuccessMessage:
          "Click 'Yes' to discard changes or 'No' to go back to '" +
          matLabel +
          "' page.",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) {
        tabGroup.selectedIndex = previousIndex;
      } else {
        switch (previousIndex) {
          case 0:
            this.basicDetailsFormGroup.patchValue(this.customerModel);
            this.basicDetailsFormGroup.controls['phone'].setValue(
              this.phoneNumber
            );
            this.basicDetailsFormGroup.markAsPristine();
            this.basicDetailsFormGroup.markAsUntouched();
            break;
          case 1:
            if (this.isAddCustomerBank)
              this.bankDetailsModel = new AddCustomerBankDetailsModel();
            this.bankDetailsFormGroup.reset();
            this.bankDetailsFormGroup.patchValue(this.bankDetailsModel);
            this.bankDetailsFormGroup.markAsPristine();
            this.bankDetailsFormGroup.markAsUntouched();
            break;
          case 2:
            if (this.isAddCustomerCard)
              this.cardDetailsModel = new AddCustomerCardDetailsModel();
            this.cardDetailsFormGroup.reset();
            this.cardDetailsFormGroup.patchValue(this.cardDetailsModel);
            this.cardDetailsFormGroup.markAsPristine();
            this.cardDetailsFormGroup.markAsUntouched();
            break;
        }
      }
    });
  }
}