import { Component, EventEmitter, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { CustomerGroupService } from 'src/app/blockModules/services/customer-group.service';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { PageChanged, SortChanged } from 'src/app/sharedModules/models/pagination.model';
import { UpdateCustomerGroupModel } from 'src/app/sharedModules/models/update-customer-group.model';
import { AddCustomerFromCustomergroupComponent } from 'src/app/featureModules/components/customer-related/add-customer-from-customergroup/add-customer-from-customergroup.component';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-customer-group',
  templateUrl: './customer-group.component.html',
  styleUrls: ['./customer-group.component.scss']
})
export class CustomerGroupComponent implements OnInit,OnDestroy {

  isLoading:boolean =false;
  loadMessage:string ="Please wait ...."
  groupName: string = ''; 
  customerGroupId: string;
  AchTransactionType: string = ''; 
  transactionTypes = [
    { value: 'Credit', displayName: 'Credit Only' },
    { value: 'Debit', displayName: 'Debit Only' },
    { value: 'Credit & Debit', displayName: 'Credit & Debit' }
  ];
  advanceFilters: string = '';
  filterCustomerGroup: ListFilter = '';
  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };
  unselectedCustomers: any[] = [];
  selectedCustomer: any[] = [];
  selectedUnselectedCustomers: any[] = [];
  selectedSelectedCustomers: any[] = [];
  highlightedRows: any[] = []; 
  updateCustomerGrpFormGroup : FormGroup;
  updateCustomerGrpModel: UpdateCustomerGroupModel;
  applyToAllForm: FormGroup; 



  customerscolumn: TableColumn[] = [
    { displayName: '', name: 'nameWithoutHeader', width: '20%' }
  ];

  customerGrpscolumn: TableColumn[] = [
    { displayName: 'CUSTMER NAME', name: 'name', width: '20%' },
    { displayName: 'EMAIL ADDRESS', name: 'email', width: '20%' },
    { displayName: 'BANK ACCT', name: 'checkSignBank', width: '10%' },
    { displayName: 'CREDIT/DEBIT', name: 'checkSignCredit', width: '15%' },
    { displayName: 'ACH TRXN TYPE', name: 'AchTransactionType', width: '15%' },
    { displayName: 'DEFAULT AMOUNT', name: 'defaultAmtText', width: '15%' }
  ];
 
  @Input()
  noRecordMessage: string;
  allColumns: TableColumn[];
  userRole :any;
  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  pageSelected: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
    previousPageIndex: 0,
  };
  datatablemargin : string ="0px";
  fxFlexCustom : string="100%";
  @ViewChild('applyToAllDialog') applyToAllDialog!: TemplateRef<any>;

  selectedCustomerList: any[] = [];

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  actionRefresh = false;

  isAmountTextInvalid = false;

  isLoadingPaging = false;

  sortBySelectedCustomers: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  constructor(private router: Router,
    private customerService: CustomerService,
      private dialog: MatDialog,
      private customerGroupService: CustomerGroupService,
      private _toastrService: ToastrService,
      private activatedRoute: ActivatedRoute,
      private formBuilder: RxFormBuilder,
      private fb: FormBuilder,
      private _commonService: CommonService) {this.allColumns = this.customerscolumn;
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    // this.filterCustomerGroup = this._commonService.getListDataFromLocalStorage(
    //   'customerGroupsGridFilter',
    //   'customerGroups',
    //   ''
    // );

   }

  ngOnInit(): void {
    this.updateCustomerGrpModel = new UpdateCustomerGroupModel();  
    this.updateCustomerGrpFormGroup = this.formBuilder.formGroup(this.updateCustomerGrpModel);
    this.applyToAllForm = this.fb.group({
      transactionTypeApplyAll: ['',Validators.required], 
      defaultAmount: ['',[Validators.required]]
    });
    this.noRecordMessage = '   '; // given space to custom message view with image
    this.activatedRoute.paramMap.subscribe((params) => {
      this.updateCustomerGrpFormGroup.patchValue({
        GroupName: params.get('groupName') || '' ,
        CustomerGroupId: params.get('customerGroupId') || ''
      });
      this.customerGroupId = params.get('customerGroupId') || '';
      this.groupName = params.get('groupName') || '';
    });
    this.GetAllActiveCustomers( this.filterCustomerGroup,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters);

      if(this.groupName==''){
        this.getAllCustomerByGroupId(this.customerGroupId);
      }
      
      this.filterEvent.subscribe(() => {
        this.onFilterCustomerGroup.bind(this);
      });
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    clearTimeout(350);
    // if(!this.router.url.includes('customers'))
    // localStorage.removeItem('customers');
    // localStorage.removeItem('customerGroups');
  }

  navigateToCustomerGroupPage(selectedIndex: any = 1){
    this.router.navigate(['customers', selectedIndex]);
  }

  openApplyToAllDialog() {
    const dialogRef = this.dialog.open(this.applyToAllDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
    });
  }
  
  onCancel() {
    this.dialog.closeAll();
  }

  handleAmountValidation(isInvalid: boolean) {
    this.isAmountTextInvalid = isInvalid;
  }

  onRefresh(id: string) {}

  onAchTransactionTypeChange(selectedType: string): void {
    this.selectedCustomer.forEach(customer => {
      customer.AchTransactionType = selectedType;
    });
  }
  onFilterCustomerGroup(event: ListFilter = '') {
    // if (this.filterCustomerGroup !== event) {
      // this.page.pageIndex = 0;
      // this.filterCustomerGroup = event;
      this.searchCustomers(event,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters);
      // this._commonService.setListDataToLocalStorage(
      //   this.filterCustomerGroup.toString(),
      //   'customerGroupsGridFilter',
      //   'customerGroups'
      // );
    // }
  }
  
  onApply() {
    const transactionType = this.applyToAllForm.get('transactionTypeApplyAll')?.value; 
    const defaultAmount = this.applyToAllForm.get('defaultAmount')?.value; 

    this.selectedCustomerList.forEach(customer => {
      customer.AchTransactionType = transactionType; 
      customer.defaultAmtText = defaultAmount; 
    });
    this.selectedCustomer = [...this.selectedCustomer, ...this.unselectedCustomers.filter(c => c.isSelected)];

    //this.updateCustomerGrpModel.AchTransactionType = transactionType;
    this.updateCustomerGrpFormGroup.patchValue({
      AchTransactionType: transactionType || '' 
    });

    this.dialog.closeAll();
    //this.selectedCustomerList = this.selectedCustomer;
    this.pageSelected.length = this.selectedCustomerList.length;
  }

  GetAllActiveCustomers(filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string){
      this.isLoading = true;
    this.customerService
      .getAllActiveCustomers(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;

          if (data.data != null) {
            //this.unselectedCustomers = data.data.customers;
            this.unselectedCustomers = [...this.unselectedCustomers, ...data.data.customers];
             this.page.length = data.data.totalRecords;

             if (this.selectedCustomer.length > 0) {
              this.selectedCustomer.forEach(customer => {
                this.unselectedCustomers = this.unselectedCustomers.filter(c => c.customerId !== customer.customerId);
              });
            }

          } else {
            this.unselectedCustomers = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }
  
  MoveAllActiveCustomersLefttoRight(filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    advanceFilters: string){
      this.isLoading = true;
    this.customerService
      .getAllActiveCustomersWithoutPage(
        filter?.toString(),
        sortBy,
        sortDirection,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;

          if (data.data != null) {

            this.selectedCustomer.push(...data.data.customers);
            this.unselectedCustomers = [];
            this.selectedUnselectedCustomers = [];
            this.updateHighlightedRows();
            const selectedType = this.updateCustomerGrpFormGroup.get('AchTransactionType')?.value;
            this.selectedCustomer.forEach(customer => {
              customer.AchTransactionType = selectedType;
            });
            this.selectedCustomer = [...this.selectedCustomer, ...this.unselectedCustomers.filter(c => c.isSelected)];
            this.unselectedCustomers = this.unselectedCustomers.filter(c => !c.isSelected);
            this.selectedCustomerList = this.selectedCustomer;
            this.pageSelected.length = data.data.totalRecords;
        
            const startIndex = this.pageSelected.pageIndex * this.pageSelected.pageSize;
              const endIndex = startIndex + this.pageSelected.pageSize;
              this.selectedCustomer = this.selectedCustomerList.slice(startIndex, endIndex);
        
              this.page.pageIndex++

          } else {
            this.selectedCustomer = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onRowClick(customer: any, type: 'unselected' | 'selected') {
    if (type === 'unselected') {
      if (this.selectedUnselectedCustomers.includes(customer)) {
        this.selectedUnselectedCustomers = this.selectedUnselectedCustomers.filter(c => c !== customer);
      } else {
        this.selectedUnselectedCustomers.push(customer);
      }
    } else {
      if (this.selectedSelectedCustomers.includes(customer)) {
        this.selectedSelectedCustomers = this.selectedSelectedCustomers.filter(c => c !== customer);
      } else {
        this.selectedSelectedCustomers.push(customer);
      }
    }
    this.updateHighlightedRows();
  }
  updateHighlightedRows() {
    this.highlightedRows = [...this.selectedUnselectedCustomers, ...this.selectedSelectedCustomers];
  }

  moveSelectedCustomersToRight() {
    const selectedType = this.updateCustomerGrpFormGroup.get('AchTransactionType')?.value;
    this.selectedUnselectedCustomers.forEach(customer => {
      customer.AchTransactionType = selectedType;
      customer.defaultAmtText = customer.defaultAmount;
      this.selectedCustomerList.push(customer);
      this.unselectedCustomers = this.unselectedCustomers.filter(c => c.customerId !== customer.customerId);
    });

    this.updateCustomerGrpModel.CreateCustomerGroupDetails = this.selectedCustomer.map((customer) => ({
      CustomerGroupId: this.updateCustomerGrpModel.CustomerGroupId,
      CustomerId: customer.customerId, 
      DefaultAmount: customer.defaultAmtText,
      IsActive: customer.isActive || true
    }));

    this.selectedCustomer = [...this.selectedCustomer, ...this.unselectedCustomers.filter(c => c.isSelected)];
  this.unselectedCustomers = this.unselectedCustomers.filter(c => !c.isSelected);

    this.selectedUnselectedCustomers = [];
    this.updateHighlightedRows();
    //this.selectedCustomerList = this.selectedCustomer;
    this.pageSelected.length = this.selectedCustomerList.length;

    const startIndex = this.pageSelected.pageIndex * this.pageSelected.pageSize;
      const endIndex = startIndex + this.pageSelected.pageSize;
      this.selectedCustomer = this.selectedCustomerList.slice(startIndex, endIndex);

      if(this.unselectedCustomers.length <5){
        this.page.pageIndex++

        this.GetAllActiveCustomers( this.filterCustomerGroup,
          this.sortBy.active,
          this.sortBy.direction,
          this.page.pageIndex,
          this.page.pageSize,
          this.advanceFilters);
      }
  }

  moveAllToRight() {

     this.MoveAllActiveCustomersLefttoRight( this.filterCustomerGroup,
          this.sortBy.active,
          this.sortBy.direction,
          this.advanceFilters);

    // this.selectedCustomer.push(...this.unselectedCustomers);
    // this.unselectedCustomers = [];
    // this.selectedUnselectedCustomers = [];
    // this.updateHighlightedRows();
    // const selectedType = this.updateCustomerGrpFormGroup.get('AchTransactionType')?.value;
    // this.selectedCustomer.forEach(customer => {
    //   customer.AchTransactionType = selectedType;
    // });
    // this.selectedCustomer = [...this.selectedCustomer, ...this.unselectedCustomers.filter(c => c.isSelected)];
    // this.unselectedCustomers = this.unselectedCustomers.filter(c => !c.isSelected);
    // this.selectedCustomerList = this.selectedCustomer;
    // this.pageSelected.length = this.selectedCustomer.length;

    // const startIndex = this.pageSelected.pageIndex * this.pageSelected.pageSize;
    //   const endIndex = startIndex + this.pageSelected.pageSize;
    //   this.selectedCustomer = this.selectedCustomerList.slice(startIndex, endIndex);

    //   this.page.pageIndex++

        // this.GetAllActiveCustomers( this.filterCustomerGroup,
        //   this.sortBy.active,
        //   this.sortBy.direction,
        //   this.page.pageIndex,
        //   this.page.pageSize,
        //   this.advanceFilters);
        
  }

  moveSelectedCustomersToLeft() {
    this.selectedSelectedCustomers.forEach(customer => {
      this.unselectedCustomers.push(customer);
      this.selectedCustomerList = this.selectedCustomerList.filter(c => c !== customer);
    });
    this.selectedCustomer = [...this.selectedCustomer, ...this.unselectedCustomers.filter(c => c.isSelected)];
    this.unselectedCustomers = this.unselectedCustomers.filter(c => !c.isSelected);
    this.selectedSelectedCustomers = [];
    this.updateHighlightedRows();
    //this.selectedCustomerList = this.selectedCustomer;
    this.pageSelected.length = this.selectedCustomerList.length;

    const startIndex = this.pageSelected.pageIndex * this.pageSelected.pageSize;
      const endIndex = startIndex + this.pageSelected.pageSize;
      this.selectedCustomer = this.selectedCustomerList.slice(startIndex, endIndex);

  }

  moveAllToLeft() {
    this.unselectedCustomers.push(...this.selectedCustomer);
    this.selectedCustomer = [];
    this.selectedSelectedCustomers = [];
    this.updateHighlightedRows();
    this.selectedCustomer = [...this.selectedCustomer, ...this.unselectedCustomers.filter(c => c.isSelected)];
    this.unselectedCustomers = this.unselectedCustomers.filter(c => !c.isSelected);
    this.selectedCustomerList = this.selectedCustomer;
    this.pageSelected.length = this.selectedCustomer.length;
  }

  updateCustomerGroup(status : any){
    this.isLoading = true;
    if (this.updateCustomerGrpFormGroup.valid) {

      this.updateCustomerGrpModel.CreateCustomerGroupDetails = this.selectedCustomerList.map((customer) => ({
        CustomerGroupId: this.updateCustomerGrpModel.CustomerGroupId,
        CustomerId: customer.customerId, 
        DefaultAmount: customer.defaultAmtText,
        IsActive: customer.isActive || true,
      }));
  
      this.updateCustomerGrpFormGroup.patchValue({ Status: status, CreateCustomerGroupDetails:  this.updateCustomerGrpModel.CreateCustomerGroupDetails});
      
      this.customerGroupService.UpdateCustomerGroup(this.updateCustomerGrpFormGroup.value,this.customerGroupId).subscribe(
        (data) => {
          this.isLoading = false;

          if (data.data != null) {
            this._toastrService.success(
              'Customer Group updated successfully'
            );
            this.navigateToCustomerGroupPage();
          } else {
            this._toastrService.error(
              data.errorMessage
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
    } else {
      this.updateCustomerGrpFormGroup.markAllAsTouched();
    }
    this.isLoading = false;
  }

  getAllCustomerByGroupId(customerGroupId: any){
    this.isLoading = true;
    this.customerGroupService.getAllCustomerByGroupId(customerGroupId)
    .subscribe((response) => {
      this.isLoading = false;
      if (response.data != null) {
        this.groupName = response.data.groupName;
        this.AchTransactionType = response.data.transactionType;
         this.selectedUnselectedCustomers = response.data.createCustomerGroupDetails;
         this.updateCustomerGrpFormGroup.patchValue({
          GroupName: response.data.groupName,
          CustomerGroupId: customerGroupId,
          AchTransactionType: response.data.transactionType
        });

        this.moveSelectedCustomersToRight();
        this.onPageChanged(this.pageSelected);
      }
      else{
        //this.data = [];
        this._toastrService.error(
          response.errorMessage
        );
      }
    }),
      (error) => {
        //this.data = [];
        this.isLoading = false;
        this._toastrService.error(
          error.errorMessage
        );
      };
  }

  onPageChanged(event: PageChanged) {
    if (this.pageSelected.pageIndex !== event.pageIndex || this.pageSelected.pageSize !== event.pageSize || this.groupName != '') {
      this.pageSelected = event;
      const startIndex = this.pageSelected.pageIndex * this.pageSelected.pageSize;
      const endIndex = startIndex + this.pageSelected.pageSize;
      this.selectedCustomer = this.selectedCustomerList.slice(startIndex, endIndex);
    }
  }

  openCustomerDialog(customerId: any = null, type: 'unselected' | 'selected' = 'selected') {
    const dialogRef = this.dialog.open(AddCustomerFromCustomergroupComponent, {
        height: '650px',
        width: '800px',
        data: { customerId }
    });

    dialogRef.afterClosed().subscribe((customer: any) => {
        if (customer?.customerId) {
            const targetList = type === 'selected' ? this.selectedCustomer : this.unselectedCustomers;
            const existingIndex = targetList.findIndex(existingCustomer => existingCustomer.customerId === customer.customerId);

            if (type === 'selected') {
                if (existingIndex === -1) {
                    this.selectedCustomer = [...targetList, customer];
                } else {
                    customer.AchTransactionType = targetList[existingIndex].AchTransactionType;
                    customer.defaultAmount = targetList[existingIndex].defaultAmount;
                    customer.defaultAmtText = targetList[existingIndex].defaultAmtText;
                    targetList[existingIndex] = customer;
                    this.selectedCustomer = [...targetList];
                }
                this.selectedCustomerList = this.selectedCustomer;
                this.pageSelected.length = this.selectedCustomer.length;

                const { pageIndex, pageSize } = this.pageSelected;
                const startIndex = pageIndex * pageSize;
                const endIndex = startIndex + pageSize;
                this.selectedCustomer = this.selectedCustomerList.slice(startIndex, endIndex);
            } else if (existingIndex !== -1) {
                this.unselectedCustomers = [
                    ...targetList.slice(0, existingIndex),
                    customer,
                    ...targetList.slice(existingIndex + 1)
                ];
            }
        }
    });
  }

  onScroll(event: any) {
    const element = event.target;

    if (element.scrollHeight === Math.floor(element.scrollTop + element.clientHeight) && !this.isLoading) {
      if (this.unselectedCustomers.length < this.page.length) {
        this.page.pageIndex++

        this.GetAllActiveCustomers( this.filterCustomerGroup,
          this.sortBy.active,
          this.sortBy.direction,
          this.page.pageIndex,
          this.page.pageSize,
          this.advanceFilters);
      }
    }
  }

  onRowDoubleClick(customer: any, type: 'unselected' | 'selected') {
    this.openCustomerDialog(customer.customerId, type);
  }

  searchCustomers(filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string){
      this.isLoading = true;
    this.customerService
      .getAllActiveCustomers(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (response) => {
          this.isLoading = false;

          if (response.data != null) {
            this.unselectedCustomers = [...response.data.customers];
             this.page.length = response.data.totalRecords;

             if (this.selectedCustomer.length > 0) {
              this.selectedCustomer.forEach(customer => {
                this.unselectedCustomers = this.unselectedCustomers.filter(c => c.customerId !== customer.customerId);
              });
            }

          } else {
            this.unselectedCustomers = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onSortChangedSelectedCustomers(event: SortChanged) {
    if (this.sortBy.active !== event.active || this.sortBy.direction !== event.direction) {
        this.sortBy = event;

        if (this.selectedCustomer) {
            const { active, direction } = this.sortBy;

            const sortedList = [...this.selectedCustomer].sort((a, b) => {
                let valueA = a[active];
                let valueB = b[active];

                if (active === 'name') {
                    valueA = `${a.firstName || ''} ${a.lastName || ''}`.trim();
                    valueB = `${b.firstName || ''} ${b.lastName || ''}`.trim();
                }

                if (valueA === undefined || valueA === null || valueA === '') return direction === 'asc' ? -1 : 1;
                if (valueB === undefined || valueB === null || valueB === '') return direction === 'asc' ? 1 : -1;

                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                    return direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else {
                    const strA = String(valueA);
                    const strB = String(valueB);
                    return direction === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
                }
            });

            this.selectedCustomer = sortedList;
        }
    }
  }

  get remainingActiveCustomerCount(): number {
    return this.page.length > this.selectedCustomerList.length ?
      this.page.length - this.selectedCustomerList.length :
      this.page.length;
  }
}