<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"></app-loading-spinner>

<div class="popup-container">
    <mat-icon class="close-icon" color="primary" (click)="closePopup(tabGroup)">close</mat-icon>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px" class="content-wrapper">
        <mat-icon class="customer-icon-display" color="primary">account_box</mat-icon>
        <div class="dynamic-label">{{ labelCustomer }}</div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px" fxFlex="100%">
            <mat-tab-group animationDuration="0ms" [selectedIndex] = "selectedIndex" #tabGroup (selectedTabChange)="selectedTabChange($event, tabGroup)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>1. Basic Details</span>
                        <mat-icon *ngIf="isBasicDetailsFilled" class="blue-check-icon">check</mat-icon>
                    </ng-template>
                    <form [formGroup]="basicDetailsFormGroup">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="320px" fxLayoutAlign="start stretch"
                            class="form-group">
                            <div fxFlex="25%" class="fontBold section-label">
                                Basic Information
                            </div>
                            <div fxFlex="25%" fxLayout="row" fxLayoutAlign="end center">
                                <mat-label>Status: </mat-label>
                                <mat-slide-toggle class="form-control" formControlName="isActive" name="isActive"
                                    style="margin-left: 8px;">
                                    <mat-label
                                        [ngStyle]="{' color': basicDetailsFormGroup.controls['isActive'].value ? 'green' : 'red'}">
                                        {{
                                        basicDetailsFormGroup.controls["isActive"].value
                                        ? "Active"
                                        : "Inactive"
                                        }}
                                    </mat-label>
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="35%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Customer Type</mat-label>
                                    <mat-select class="form-control" formControlName="type" name="type"
                                        (selectionChange)="onCustomerTypeChange($event)">
                                        <mat-option *ngFor="let customerType of customerTypeList"
                                            [value]="customerType.key">{{ customerType.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column" fxLayoutWrap fxFlex="35%" *ngIf="!isCompany">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">First Name</mat-label>
                                    <input matInput formControlName="firstName" class="form-control" name="firstName"
                                        placeholder="First Name" autocomplete="off" maxlength="30" />
                                    <mat-error>
                                        {{
                                        basicDetailsFormGroup.controls["firstName"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column" fxLayoutWrap fxFlex="75%" *ngIf="isCompany">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Company Name</mat-label>
                                    <input matInput formControlName="firstName" class="form-control" name="firstName"
                                        placeholder="Company Name" autocomplete="off" maxlength="30" />
                                    <mat-error>
                                        {{
                                        basicDetailsFormGroup.controls["firstName"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="column" fxLayoutWrap fxFlex="35%" *ngIf="!isCompany">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Last Name</mat-label>
                                    <input matInput formControlName="lastName" class="form-control" name="lastName"
                                        placeholder="Last Name" autocomplete="off" maxlength="30" />
                                    <mat-error>
                                        {{
                                        basicDetailsFormGroup.controls["lastName"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="50%">
                                <ngx-intl-tel-input [ngClass]="{
                                    'phone-invaid':
                                      basicDetailsFormGroup.controls['phone'].invalid &&
                                      basicDetailsFormGroup.controls['phone'].touched,
                                    'phone-valid': basicDetailsFormGroup.controls['phone'].valid
                                  }" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true" [customPlaceholder]="'Mobile Number'"
                                    [searchCountryFlag]="true" [searchCountryField]="[
                                    SearchCountryField.Iso2,
                                    SearchCountryField.Name
                                  ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                                    [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                    [numberFormat]="PhoneNumberFormat.National" [value]="phoneNumber" name="phone"
                                    formControlName="phone" [maxLength]="maxLength"
                                    (countryChange)="changePhone($event)">
                                </ngx-intl-tel-input>
                                <mat-error *ngIf="basicDetailsFormGroup.controls['phone'].touched">{{
                                    basicDetailsFormGroup.controls["phone"]["_errorMessage"]
                                    }}</mat-error>
                                <div *ngIf="phonehint" class="phonehint-text">
                                    eg.: {{ phonehint }}
                                </div>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="50%">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Email Address</mat-label>
                                    <input matInput class="form-control" formControlName="email" name="email"
                                        placeholder="Email Address" autocomplete="off" maxlength="50"
                                        style="padding: 2px" />
                                    <mat-error>
                                        {{ basicDetailsFormGroup.controls["email"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="fontBold address-section-label">Address</div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="50%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput formControlName="address1" class="form-control" name="address1"
                                        placeholder="Address Line 1" autocomplete="off" maxlength="500" />
                                    <mat-error>
                                        {{
                                        basicDetailsFormGroup.controls["address1"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="50%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Address Line 2</mat-label>
                                    <input matInput formControlName="address2" class="form-control" name="address2"
                                        placeholder="Address Line 2" autocomplete="off" maxlength="500" />
                                    <mat-error>
                                        {{
                                        basicDetailsFormGroup.controls["address2"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="35%">
                                <mat-form-field appearance="outline">
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="city" class="form-control" name="city"
                                        placeholder="City" autocomplete="off" maxlength="30" />
                                    <mat-error>
                                        {{ basicDetailsFormGroup.controls["city"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="35%">
                                <mat-form-field appearance="outline">
                                    <mat-label>State</mat-label>
                                    <mat-select class="form-control" formControlName="state" name="state">
                                        <mat-option *ngFor="let stateOpt of states" [value]="stateOpt.postalCode">{{
                                            stateOpt.state }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{ basicDetailsFormGroup.controls["state"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="35%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Zip</mat-label>
                                    <input matInput class="form-control" formControlName="zip" name="zip"
                                        placeholder="Zip" autocomplete="off" [mask]="'00000'" [showMaskTyped]="true" />
                                    <mat-error>
                                        {{ basicDetailsFormGroup.controls["zip"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                    <div class="basic-details-buttons-container-footer">
                        <button mat-stroked-button color="primary" (click)="closePopup(tabGroup)">CANCEL</button>
                        <button mat-raised-button color="primary" (click)="addCustomerBasicDetails(tabGroup, 0)">SAVE &
                            NEXT</button>
                    </div>
                </mat-tab>

                <mat-tab [disabled]="isTabDisabled">
                    <ng-template mat-tab-label>
                        <span>2. Bank Details</span>
                        <mat-icon *ngIf="isBankDetailsFilled" class="blue-check-icon">check</mat-icon>
                    </ng-template>
                    <form [formGroup]="bankDetailsFormGroup">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="320px" fxLayoutAlign="start stretch"
                            class="form-group">
                            <div fxFlex="25%" class="fontBold section-label">
                                Add Bank Account
                            </div>
                            <div fxFlex="25%" fxLayout="row" fxLayoutAlign="end center">
                                <mat-label>Status: </mat-label>
                                <mat-slide-toggle class="form-control" formControlName="status" name="status"
                                    style="margin-left: 8px;">
                                    <mat-label
                                        [ngStyle]="{' color': bankDetailsFormGroup.controls['status'].value ? 'green' : 'red'}">
                                        {{
                                        bankDetailsFormGroup.controls["status"].value
                                        ? "Active"
                                        : "Inactive"
                                        }}
                                    </mat-label>
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="71%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Bank Name</mat-label>
                                    <input matInput placeholder="Bank Name (min 3 characters)"
                                        formControlName="bankName" [matAutocomplete]="auto" maxlength="200" />
                                    <button *ngIf="bankDetailsFormGroup.controls['bankName'].value" matSuffix
                                        mat-icon-button aria-label="Clear" (click)="clearSelection()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option *ngIf="isSearchLoading" class="is-loading">Loading...</mat-option>
                                        <ng-container *ngIf="!isSearchLoading && bankNameList.length > 0">
                                            <mat-option *ngFor="let bankname of bankNameList" [value]="bankname">
                                                {{ bankname }}
                                            </mat-option>
                                        </ng-container>
                                        <ng-container *ngIf="isShowNoFound && !isSearchLoading && !bankNameList.length">
                                            <mat-option>
                                                <div class="name-no-record">
                                                    <b>No Records Found</b>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                    <mat-error>
                                        {{ bankDetailsFormGroup.controls["bankName"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="25%">
                                <div fxLayout="row" fxLayoutWrap fxLayoutGap="9px" fxLayoutAlign="start center"
                                    style="margin-top: 6%">
                                    <div fxLayout="column" fxLayoutWrap>
                                        <mat-label>Is Primary: </mat-label>
                                    </div>
                                    <div fxLayout="column" fxLayoutWrap>
                                        <mat-slide-toggle formControlName="isPrimary" name="isPrimary"
                                            class="form-control">
                                            <mat-label>
                                                {{
                                                bankDetailsFormGroup.controls["isPrimary"].value
                                                ? "Yes"
                                                : "No"
                                                }}
                                            </mat-label>
                                        </mat-slide-toggle>
                                        <mat-error>
                                            {{
                                            bankDetailsFormGroup.controls["isPrimary"]["_errorMessage"]
                                            }}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="33%">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Account Number</mat-label>
                                    <input matInput formControlName="bankAccountNumber" class="form-control"
                                        name="bankAccountNumber" placeholder="Account Number" autocomplete="off"
                                        maxlength="17" />
                                    <mat-error>
                                        {{
                                        bankDetailsFormGroup.controls["bankAccountNumber"][
                                        "_errorMessage"
                                        ]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="33%">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Routing Number</mat-label>
                                    <input matInput formControlName="routingNumber" class="form-control"
                                        name="routingNumber" placeholder="Routing Number" autocomplete="off"
                                        maxlength="9" />
                                    <mat-error>
                                        {{
                                        bankDetailsFormGroup.controls["routingNumber"][
                                        "_errorMessage"
                                        ]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="33%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Account Type</mat-label>
                                    <mat-select class="form-control" formControlName="accountType" name="accountType">
                                        <mat-option *ngFor="let at of accountType" [value]="at.value">
                                            {{ at.display }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{
                                        bankDetailsFormGroup.controls["accountType"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayoutAlign="end center">
                            <button [disabled]="isBankReset" mat-stroked-button color="primary"
                                (click)="resetBankForm()">RESET</button>
                            <button mat-raised-button color="primary" (click)="addCustomerBankDetails(tabGroup, 1)"
                                style="margin-left: 8px;">
                                {{lableAddUpdateBank}}
                            </button>
                        </div>
                        <div fxLayoutAlign="start center" class="fontBold bank-card-section-label">Bank Accounts</div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
                            <div fxLayout="column" fxFlex="100%" style="margin-top: -11px;">
                                <app-data-table [columns]="bankColumns" [data]="bankList" [isLoading]="isLoading"
                                    [role]="this.userRole" [noRecordMessage]="noRecordMessage" [isExport]="false"
                                    [isFilter]="false" [showMoreMenu]="false" [isSearch]="false" [showPaginator]="false"
                                    [datatablemargin]="this.datatableMargin" [isSpacerRequired]="false"
                                    [sortBy]="sortByBank" (sortChangedEvent)="onBankSortChanged($event)"
                                    (bankEditEvent)="bankEdit($event)" [isScroll]="true">
                                </app-data-table>
                            </div>
                        </div>
                    </form>
                    <div class="buttons-container-footer">
                        <button mat-stroked-button color="primary" (click)="closePopup(tabGroup)">CANCEL</button>
                        <button mat-raised-button color="primary" (click)="saveAndNext(tabGroup, 1)">NEXT</button>
                    </div>
                </mat-tab>

                <mat-tab [disabled]="isTabDisabled">
                    <ng-template mat-tab-label>
                        <span>3. Card Details</span>
                        <mat-icon *ngIf="isCardDetailsFilled" class="blue-check-icon">check</mat-icon>
                    </ng-template>
                    <form [formGroup]="cardDetailsFormGroup">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="320px" fxLayoutAlign="start stretch"
                            class="form-group">
                            <div fxFlex="25%" class="fontBold section-label">
                                Add Card Details
                            </div>
                            <div fxFlex="25%" fxLayout="row" fxLayoutAlign="end center">
                                <mat-label>Status: </mat-label>
                                <mat-slide-toggle class="form-control" formControlName="status" name="status"
                                    style="margin-left: 8px;">
                                    <mat-label
                                        [ngStyle]="{' color': bankDetailsFormGroup.controls['status'].value ? 'green' : 'red'}">
                                        {{
                                        bankDetailsFormGroup.controls["status"].value
                                        ? "Active"
                                        : "Inactive"
                                        }}
                                    </mat-label>
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="30%">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Name On Card</mat-label>
                                    <input matInput formControlName="nameOnCard" class="form-control" name="nameOnCard"
                                        placeholder="Name On Card" autocomplete="off" maxlength="100" />
                                    <mat-error>
                                        {{
                                        cardDetailsFormGroup.controls["nameOnCard"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="30%">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Card Number</mat-label>
                                    <input matInput formControlName="cardNumber" class="form-control" name="cardNumber"
                                        placeholder="Card Number" autocomplete="off" (keyup)="getCardImage()"
                                        maxlength="19" />
                                    <img matSuffix width="34" height="24" src="{{ cardImageName }}" />
                                    <mat-error>
                                        {{
                                        cardDetailsFormGroup.controls["cardNumber"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="17%">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Exp Month</mat-label>
                                    <mat-select class="form-control" formControlName="expMonth" name="expMonth">
                                        <mat-option *ngFor="let month of monthList" [value]="month">{{ month }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{ cardDetailsFormGroup.controls["expMonth"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="15%">
                                <mat-form-field appearance="outline">
                                    <mat-label class="required">Exp Year</mat-label>
                                    <mat-select class="form-control" formControlName="expYear" name="expYear">
                                        <mat-option *ngFor="let year of yearList" [value]="year">{{ year }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{ cardDetailsFormGroup.controls["expYear"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayoutAlign="start center" style="padding-bottom: 10px;">
                            <mat-checkbox [checked]="isCardAddressSameAsBasicAddress"
                                (change)="updateCardAddress($event.checked)">Same addresss as in "Basic
                                Details"</mat-checkbox>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="100%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Address Line</mat-label>
                                    <input matInput formControlName="address" class="form-control" name="address"
                                        placeholder="Address Line" autocomplete="off" maxlength="900" />
                                    <mat-error>
                                        {{
                                        cardDetailsFormGroup.controls["address"]["_errorMessage"]
                                        }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                            fxLayoutAlign="start stretch" class="form-group">
                            <div fxLayout="column" fxLayoutWrap fxFlex="35%">
                                <mat-form-field appearance="outline">
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="city" class="form-control" name="city"
                                        placeholder="City" autocomplete="off" maxlength="30" />
                                    <mat-error>
                                        {{ cardDetailsFormGroup.controls["city"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="35%">
                                <mat-form-field appearance="outline">
                                    <mat-label>State</mat-label>
                                    <mat-select class="form-control" formControlName="state" name="state">
                                        <mat-option *ngFor="let stateOpt of states" [value]="stateOpt.postalCode">{{
                                            stateOpt.state }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{ cardDetailsFormGroup.controls["state"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutWrap fxFlex="35%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Zip</mat-label>
                                    <input matInput class="form-control" formControlName="zip" name="zip"
                                        placeholder=" Zip" autocomplete="off" [mask]="'00000'" [showMaskTyped]="true" />
                                    <mat-error>
                                        {{ cardDetailsFormGroup.controls["zip"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayoutAlign="end center">
                            <button [disabled]="isCardReset" mat-stroked-button color="primary"
                                (click)="resetCardForm()">RESET</button>
                            <button mat-raised-button color="primary" (click)="addCustomerCardDetails(tabGroup, 2)"
                                style="margin-left: 8px;">
                                {{lableAddUpdateCard}}
                            </button>
                        </div>
                        <div fxLayoutAlign="start center" class="fontBold bank-card-section-label">Card Details</div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
                            <div fxLayout="column" fxFlex="100%" style="margin-top: -11px;">
                                <app-data-table [columns]="cardColumns" [data]="debitCreditCardsList"
                                    [isLoading]="isLoading" [role]="this.userRole" [noRecordMessage]="noRecordMessage"
                                    [isExport]="false" [isFilter]="false" [showMoreMenu]="false" [isSearch]="false"
                                    [showPaginator]="false" [datatablemargin]="this.datatableMargin"
                                    [isSpacerRequired]="false" [sortBy]="sortByCard" [isScroll]="true"
                                    (sortChangedEvent)="onCardSortChanged($event)" (cardEditEvent)="cardEdit($event)">
                                </app-data-table>
                            </div>
                        </div>
                        <div class="buttons-container-footer">
                            <button mat-stroked-button color="primary" (click)="closePopup(tabGroup)">CANCEL</button>
                            <button mat-raised-button color="primary" (click)="saveAndNext(tabGroup, 2)">NEXT</button>
                        </div>
                    </form>
                </mat-tab>

                <mat-tab label="4. Summary" [disabled]="isTabDisabled">
                    <div fxLayoutAlign="start center" class="fontBold section-label">
                        Basic Details
                        <mat-icon color="primary" class="blue-edit-icon"
                            (click)="navigateToSelectedTab(tabGroup, 0)">edit</mat-icon>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxFlex="100%">
                            <div fxLayout="row" fxLayoutWrap fxFlex="40%">
                                Full Name:&nbsp;
                                <mat-label class="fontBold">
                                    {{ customerModel?.firstName }} {{ customerModel?.lastName}}
                                </mat-label>
                            </div>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxFlex="60%">
                                Mobile Number:&nbsp;
                                <mat-label class="fontBold">
                                    <ngx-intl-tel-input class="showMobileFileds"
                                        [preferredCountries]="preferredCountries" [formControl]="mobileNumberControl">
                                    </ngx-intl-tel-input>
                                </mat-label>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxFlex="100%">
                            <div fxLayout="row" fxLayoutWrap fxFlex="40%">
                                Customer Type:&nbsp;
                                <mat-label class="fontBold">
                                    {{customerModel?.type == true ? "Company" : "Individual"}}
                                </mat-label>
                            </div>
                            <div fxLayout="row" fxLayoutWrap fxFlex="60%">
                                Email Address:&nbsp;
                                <mat-label class="fontBold">
                                    {{ customerModel?.email }}
                                </mat-label>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxFlex="100%">
                            <div fxLayout="row" fxLayoutWrap fxFlex="40%">
                                Created On:&nbsp;
                                <mat-label class="fontBold">
                                    {{customerModel?.entryDate | date : "MMMM d, y, hh:mm a"}}
                                </mat-label>
                            </div>
                            <div fxLayout="row" fxLayoutWrap fxFlex="60%">
                                Address:&nbsp;
                                <mat-label class="fontBold">
                                    <span *ngIf="customerModel?.address1">
                                        {{customerModel?.address1.trim()}}<span
                                            *ngIf="customerModel?.address2 || customerModel?.city || customerModel?.state || customerModel?.zip">,
                                        </span>
                                    </span>
                                    <span *ngIf="customerModel?.address2">
                                        {{customerModel?.address2.trim()}}<span
                                            *ngIf="customerModel?.city || customerModel?.state || customerModel?.zip">,
                                        </span>
                                    </span>
                                    <span *ngIf="customerModel?.city">
                                        {{customerModel?.city.trim()}}<span
                                            *ngIf="customerModel?.state || customerModel?.zip">, </span>
                                    </span>
                                    <span *ngIf="customerModel?.state">
                                        {{customerModel?.state.trim()}}<span *ngIf="customerModel?.zip">, </span>
                                    </span>
                                    <span *ngIf="customerModel?.zip">
                                        {{customerModel?.zip.trim()}}
                                    </span>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                    <div fxLayoutAlign="start center" class="fontBold section-label">
                        Bank Details
                        <mat-icon color="primary" class="blue-edit-icon"
                            (click)="navigateToSelectedTab(tabGroup, 1)">edit</mat-icon>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
                        <div fxLayout="column" fxFlex="100%" style="margin-top: -11px;">
                            <app-data-table [columns]="bankColumnsSummary" [data]="bankList" [isLoading]="isLoading"
                                [role]="this.userRole" [noRecordMessage]="noRecordMessage" [isExport]="false"
                                [isFilter]="false" [showMoreMenu]="false" [isSearch]="false" [showPaginator]="false"
                                [datatablemargin]="this.datatableMargin" [isSpacerRequired]="false" [isScroll]="true"
                                [sortBy]="sortByBank" (sortChangedEvent)="onBankSortChanged($event)">
                            </app-data-table>
                        </div>
                    </div>
                    <div fxLayoutAlign="start center" class="fontBold section-label">
                        Card Details
                        <mat-icon color="primary" class="blue-edit-icon"
                            (click)="navigateToSelectedTab(tabGroup, 2)">edit</mat-icon>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
                        <div fxLayout="column" fxFlex="100%" style="margin-top: -11px;">
                            <app-data-table [columns]="cardColumnsSummary" [data]="debitCreditCardsList"
                                [isLoading]="isLoading" [role]="this.userRole" [noRecordMessage]="noRecordMessage"
                                [isExport]="false" [isFilter]="false" [showMoreMenu]="false" [isSearch]="false"
                                [showPaginator]="false" [datatablemargin]="this.datatableMargin"
                                [isSpacerRequired]="false" [sortBy]="sortByCard" [isScroll]="true"
                                (sortChangedEvent)="onCardSortChanged($event)">
                            </app-data-table>
                        </div>
                    </div>
                    <div class="buttons-container-footer">
                        <button mat-stroked-button color="primary" (click)="closePopup(tabGroup)">CLOSE</button>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>