<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<mat-drawer-container>
  <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="apply-filter">
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>
    </div>
    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="filterFormGroup">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
            class="form-group form-field-bottom">
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select class="form-control" formControlName="type" name="type">
                <mat-option *ngFor="let type of typeList" [value]="type.key">{{ type.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="form-field-bottom">
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>State</mat-label>
              <mat-select class="form-control" formControlName="state" name="state">
                <mat-option *ngFor="let state of states" [value]="state?.postalCode">{{ state?.state }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="form-field-bottom">
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>City</mat-label>
              <input matInput class="form-control" formControlName="city" type="text" name="city" placholder="City"
                autocomplete="off" />
              <mat-error>
                {{ filterFormGroup.controls["city"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="form-field-bottom">
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Zip</mat-label>
              <input matInput class="form-control" formControlName="zip" type="text" name="zip" placholder="Zip"
                autocomplete="off" [mask]="'00000'" [showMaskTyped]="true" />
              <mat-error>
                {{ filterFormGroup.controls["zip"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button mat-raised-button color="primary" class="page-header-button-style" (click)="applyAdvanceFilter()">
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer #drawerCustomerGroup position="end" class="apply-filter-mat-drawer-height">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="apply-filter">
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawerCustomerGroup.close()">close</mat-icon>
      </div>
    </div>
    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="customerGroupFilterFormGroup">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
            class="form-group form-field-bottom">
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>ACH Transaction Type</mat-label>
              <mat-select class="form-control" formControlName="achTransactionType" name="achTransactionType">
                <mat-option *ngFor="let achTransactionType of achTransactionTypeList" [value]="achTransactionType.value">{{ achTransactionType.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
          class="form-group form-field-bottom">
          <mat-form-field fxFlex="100%" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select class="form-control" formControlName="status" name="status">
              <mat-option *ngFor="let status of customerGroupStatusList" [value]="status.value">{{ status.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restCustomerGroupFilterForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button mat-raised-button color="primary" class="page-header-button-style" (click)="applyCustomerGroupAdvanceFilter()">
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <div class="page-header-bottom">
      <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
            <div class="page-header-text">Customers</div>
            <div class="page-sub-header-text-1">
              All individual & company related data can be managed from this
              page
            </div>
          </div>
        </div>

        <!-- <span class="spacer"></span>

        <div fxLayout="column" fxLayoutAlign="center end">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
            <button *ngIf="this.userRole != '1'" mat-raised-button color="primary" class="page-header-button-style"
              (click)="AddCustomer()">
              ADD
            </button>
          </div>
        </div> -->
      </div>
    </div>
    <div class="invoice-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
      <div fxLayout="column" class="totalTransactionsDiv" fxLayoutGap="5px" fxFlex="100%">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex">
      <mat-tab label="Customers">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
          <div fxLayout="column" fxFlex="100%">
            <app-data-table [columns]="columns" [data]="data" [isExport]="false" [isLoading]="isLoading"
              [isLoadingPaging]="isLoadingPaging" [page]="page" [sortBy]="sortBy" [noRecordMessage]="noRecordMessage"
              [actionDelete]="actionDelete" [showMoreMenu]="true" [allColumns]="allColumns" [selection]="selection"
              (actionDeleteEvent)="onDelete($event)" [deletedFilter]="deletedFilter" [actionRefresh]="actionRefresh"
              (actionRefreshEvent)="onRefresh($event)" (pageChangedEvent)="onPageChanged($event)"
              (rowClickedEvent)="onRowClick($event)" (sortChangedEvent)="onSortChanged($event)"
              (onFilterEvent)="onFilter($event)" (advanceFilterEvent)="openFilter(); drawer.toggle()"
              (deleteBasedOnSelectionEvent)="deleteBasedOnSelection($event)" (customerEditEvent)="customerEdit($event)"
              [filterText]="this.filter?.toString() ?? ''" [advanceFilterCount]="advanceFilterCount"
              [role]="this.userRole"
              [isSpacerRequired]="true"
              [isCreateNewBtn]="true"
              (navigationToPageEvent)="AddCustomer()">
            </app-data-table>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Customer Groups">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
          <div fxLayout="column" fxFlex="100%">
            <app-data-table [columns]="CGroupcolumns" [data]="customerGroupdata" [isExport]="false" [isLoading]="isLoading"
            [isLoadingPaging]="isLoadingPaging" [page]="pageCustomerGroup" [sortBy]="sortByCustomerGroup" [noRecordMessage]="noRecordMessage"
            [actionDelete]="actionDelete" [showMoreMenu]="true" [allColumns]="allCustomerGroupColumns" [selection]="selection"
            (actionDeleteEvent)="onDelete($event)" [deletedFilter]="deletedFilterCustomerGroup" [actionRefresh]="actionRefresh"
            (actionRefreshEvent)="onRefresh($event)" (pageChangedEvent)="onPageChangedCustomerGroup($event)"
            (rowClickedEvent)="onRowClick($event)" (sortChangedEvent)="onSortChangedCustomerGroup($event)"
            (onFilterEvent)="onFilterCustomerGroup($event)" (advanceFilterEvent)="openFilter(); drawerCustomerGroup.toggle()"
            (deleteBasedOnSelectionEvent)="deleteBasedOnSelection($event)" (customerEditEvent)="customerEdit($event)"
            [filterText]="this.filterCustomerGroup?.toString() ?? ''" [advanceFilterCount]="advanceCustomerGroupFilterCount"
            [role]="this.userRole"
            [isSpacerRequired]="true"
            [isCreateNewBtn]="true"
            (navigationToPageEvent)="OpenDialogForCustomerGrp()"
            (customerGroupViewEvent)="customerGroupView($event)"
            (customerGroupEditEvent)="customerGroupEdit($event)"
            (customerGroupDeleteEvent)="customerGroupDelete($event)">
          </app-data-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
</div>
</div>
  </mat-drawer-content>
</mat-drawer-container>


<!-- Dialog Template -->
<ng-template #customerGroupDialog>
  <h4 mat-dialog-title fxLayoutAlign="center end"><b>Create Customer Group</b></h4>
  <form [formGroup]="createCustomerGrpFormGroup">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="form-field-bottom">
   
    <mat-form-field fxFlex="100%" appearance="outline">
      <mat-label>Group Name</mat-label>
      <input matInput class="form-control" formControlName="GroupName" type="text" name="GroupName" placholder="Group Name"
        autocomplete="off" maxlength="60"/>
      <mat-error>
        {{ createCustomerGrpFormGroup.controls["GroupName"]["_errorMessage"] }}
      </mat-error>
    </mat-form-field>
  
  </div>

  <div  mat-dialog-actions fxLayoutAlign="end end">
    <button class="white-button-style" appearance="outline" (click)="onCancel()" mat-button>
      <span >CANCEL</span>
    </button>
    <button mat-raised-button color="primary" [disabled]="createCustomerGrpFormGroup.invalid" class="page-header-button-style" (click)="AddCustomerGroup()">CREATE</button>
  </div>
</form>
</ng-template>