import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../sharedModules/shared.module';
import { AddAchTransactionComponent } from './components/ach-related/add-ach-transaction/add-ach-transaction.component';
import { CustomerProfileComponent } from './components/customer-related/customer-profile/customer-profile.component';
import { CustomerDetailsComponent } from './components/customer-related/customer-details/customer-details.component';
import { BankAndCardDetailsComponent } from './components/customer-related/bank-and-card-details/bank-and-card-details.component';
import { CustomerTransactionsComponent } from './components/customer-related/customer-transactions/customer-transactions.component';
import { BankAccountsComponent } from './components/customer-related/bank-accounts/bank-accounts.component';
import { DebitCreditCardsComponent } from './components/customer-related/debit-credit-cards/debit-credit-cards.component';
import { VirtualCardsComponent } from './components/customer-related/virtuals-cards/virtuals-cards.component';
import { AddCustomerComponent } from './components/customer-related/add-customer/add-customer.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { CustomerRefreshDetailsComponent } from './components/instant-verify-related/customer-refresh-details/customer-refresh-details.component';
import { AddInstantBankVerifyComponent } from './components/instant-verify-related/add-instant-bank-verify/add-instant-bank-verify.component';
import { InstantVerifyReportComponent } from './components/instant-verify-related/instant-verify-report/instant-verify-report.component';
import { InstantVerifyReportDDAComponent } from './components/instant-verify-related/instant-verify-report/instant-verify-report-dda/instant-verify-report-dda.component';
import { InstantVerifyReportSDAComponent } from './components/instant-verify-related/instant-verify-report/instant-verify-report-sda/instant-verify-report-sda.component';
import { InstantVerifyReportCCAComponent } from './components/instant-verify-related/instant-verify-report/instant-verify-report-cca/instant-verify-report-cca.component';
import { InstantVerifyReportINVComponent } from './components/instant-verify-related/instant-verify-report/instant-verify-report-inv/instant-verify-report-inv.component';
import { InstantVerifyReportBPAComponent } from './components/instant-verify-related/instant-verify-report/instant-verify-report-bpa/instant-verify-report-bpa.component';
import { BankQuickViewComponent } from './components/instant-verify-related/instant-verify-report/reports-component/bank-quick-view/bank-quick-view.component';
import { ReportTransactionsComponent } from './components/instant-verify-related/instant-verify-report/reports-component/report-transactions-card/report-transactions-card.component';
import { CCABalanceCardComponent } from './components/instant-verify-related/instant-verify-report/reports-component/cca-balance-card/cca-balance-card.component';
import { InvBalanceCardComponent } from './components/instant-verify-related/instant-verify-report/reports-component/inv-balance-card/inv-balance-card.component';
import { BpaBalanceCardComponent } from './components/instant-verify-related/instant-verify-report/reports-component/bpa-balance-card/bpa-balance-card.component';
import { Ng5SliderModule } from 'ng5-slider';
import { CalenderSliderCardComponent } from './components/instant-verify-related/instant-verify-report/reports-component/calender-slider-card/calender-slider-card.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DashboardStatsComponent } from './components/dashboard-related/dashboard-stats/dashboard-stats.component';
import { DashboardTotalTransactionsComponent } from './components/dashboard-related/dashboard-total-transactions/dashboard-total-transactions.component';
import { DashboardRecurringTransactionsComponent } from './components/dashboard-related/dashboard-recurring-transactions/dashboard-recurring-transactions.component';
import { NgChartsModule } from 'ng2-charts';
import { DashboardProductsReportsComponent } from './components/dashboard-related/dashboard-products-reports/dashboard-products-reports.component';
import { DashboardAchTransactionReportComponent } from './components/dashboard-related/dashboard-ach-transaction-report/dashboard-ach-transaction-report.component';
import { DashboardCreditdebitTransactionReportComponent } from './components/dashboard-related/dashboard-creditdebit-transaction-report/dashboard-creditdebit-transaction-report.component';
import { DashboardInstantfundTransactionReportComponent } from './components/dashboard-related/dashboard-instantfund-transaction-report/dashboard-instantfund-transaction-report.component';
import { DashboardEcheckTransactionReportComponent } from './components/dashboard-related/dashboard-echeck-transaction-report/dashboard-echeck-transaction-report.component';
import { DashboardPieChartReportsComponent } from './components/dashboard-related/dashboard-pie-chart-reports/dashboard-pie-chart-reports.component';
import { DashboardTransactionAmountChartComponent } from './components/dashboard-related/dashboard-transaction-amount-chart/dashboard-transaction-amount-chart.component';
import { DashboardReportTotalTransactionsComponent } from './components/dashboard-related/dashboard-report-total-transactions/dashboard-report-total-transactions.component';
import { DashboardPieChartComponent } from './components/dashboard-related/dashboard-pie-chart-reports/dashboard-pie-chart/dashboard-pie-chart.component';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { SendOtpComponent } from '../blockModules/pages/login/send-otp/send-otp.component';
import { DashboardReportServiceBasedComponent } from './components/dashboard-related/dashboard-report-service-based/dashboard-report-service-based.component';
import { CustomerCreditdebitTransactionComponent } from './components/transactions-related/creditdebit/customer-creditdebit-transaction/customer-creditdebit-transaction.component';
import { CreditdebitTransactionsSummaryComponent } from './components/transactions-related/creditdebit/creditdebit-transactions-summary/creditdebit-transactions-summary.component';
import { EcheckTransactionsSummaryComponent } from './components/transactions-related/echeck/echeck-transactions-summary/echeck-transactions-summary.component';
import { AddEcheckTransactionComponent } from './components/transactions-related/echeck/add-echeck-transaction/add-echeck-transaction.component';
import { CustomerEcheckTransactionComponent } from './components/transactions-related/echeck/customer-echeck-transaction/customer-echeck-transaction.component';
import { InstantFundTransactionsSummaryComponent } from './components/transactions-related/instantfund/instantfund-transactions-summary/instantfund-transactions-summary.component';
import { AddInstantFundTransactionComponent } from './components/transactions-related/instantfund/add-instantfund-transaction/add-instantfund-transaction.component';
import { CustomerInstantFundTransactionComponent } from './components/transactions-related/instantfund/customer-instantfund-transaction/customer-instantfund-transaction.component';
import { DashboardReportReturnRateComponent } from './components/dashboard-related/dashboard-report-return-rate/dashboard-report-return-rate.component';
import { DashboardReportTransactionStatusComponent } from './components/dashboard-related/dashboard-report-transaction-status/dashboard-report-transaction-status.component';
import { DashboardReportTotalAmountComponent } from './components/dashboard-related/dashboard-report-total-amount/dashboard-report-total-amount.component';
import { AddCreditdebitTransactionComponent } from './components/transactions-related/creditdebit/add-creditdebit-transaction/add-creditdebit-transaction.component';
import { GenerateReportsComponent } from './components/report-related/generate-report/generate-report.component';
import { ReportsStatsComponent } from './components/report-related/reports/reports-stats/reports-stats.component';
import { ReportAmountTransactionComponent } from './components/report-related/reports/report-amount-transaction/report-amount-transaction.component';
import { ReportsRecurringComponent } from './components/report-related/reports/reports-recurring/reports-recurring.component';
import { ReportTotalTransactionComponent } from './components/report-related/reports/report-total-transaction/report-total-transaction.component';
import { ReportTransactionAmountChartComponent } from './components/report-related/reports/report-transaction-amount-chart/report-transaction-amount-chart.component';
import { InstantVerifyReportsService } from './services/instant-verify.reports.service';
import { GenerateReportGridComponent } from './components/report-related/generate-report-grid/generate-report-grid.component';
import { AchTransactionsSummaryComponent } from './components/transactions-related/ach/ach-transactions-summary/ach-transactions-summary.component';
import { CustomerAchTransactionComponent } from './components/transactions-related/ach/customer-ach-transaction/customer-ach-transaction.component';
import { AddUserComponent } from './components/user-management/add-user/add-user.component';
import { EditUserComponent } from './components/user-management/edit-user/edit-user.component';
import { FlagsComponent } from './components/instant-verify-related/instant-verify-report/reports-component/flags/flags.component';
import { EmployerVerificationComponent } from './components/instant-verify-related/instant-verify-report/reports-component/employer-verification/employer-verification.component';
import { DepositsComponent } from './components/instant-verify-related/instant-verify-report/reports-component/deposits/deposits.component';
import { RecuringWithdrawalsComponent } from './components/instant-verify-related/instant-verify-report/reports-component/recuring-withdrawals/recuring-withdrawals.component';
import { DtiQuickViewComponent } from './components/instant-verify-related/instant-verify-report/reports-component/dti-quick-view/dti-quick-view.component';
import { FinzeoGradeComponent } from './components/instant-verify-related/instant-verify-report/reports-component/finzeo-grade/finzeo-grade.component';
import { PotentialPaymentAmountsComponent } from './components/instant-verify-related/instant-verify-report/reports-component/potential-payment-amounts/potential-payment-amounts.component';
import { DrawArcComponent } from './components/instant-verify-related/instant-verify-report/reports-component/finzeo-grade/draw-arc.component';
import { DrawLinearGuageComponent } from './components/instant-verify-related/instant-verify-report/reports-component/potential-payment-amounts/draw-linear-guage.component';
import { AccountSummaryComponent } from './components/transactions-related/account-summary/account-summary.component';
import { StatementHistoryComponent } from './components/statement-related/statement-history/statement-history.component';
import { CurrentStatementComponent } from './components/statement-related/current-statement/current-statement.component';
import { GenerateInvoiceComponent } from './components/invoice-related/generate-invoice/generate-invoice.component';
import { DynamicInvoiceComponent } from './components/invoice-related/dynamic-invoice/dynamic-invoice/dynamic-invoice.component';
import { InvoiceSetupComponent } from './components/settings-related/invoice-setup/invoice-setup.component';
import { EmailSetupComponent } from './components/settings-related/email-setup/email-setup.component';
import { ManageNotificationComponent } from './components/settings-related/manage-notification/manage-notification.component';
import { SettingsComponent } from './components/settings-related/setting/settings/settings.component';
import { InvoicePreviewComponent } from './components/settings-related/invoice-preview/invoice-preview.component';
import { InvoicePreviewDarkComponent } from './components/settings-related/invoice-preview-dark/invoice-preview-dark.component';
import { InvoiceDashboardTotalInvoicesComponent } from './components/invoice-related/invoice-dashboard-total-invoices/invoice-dashboard-total-invoices.component';
import { InvoiceDashboardPaymentMethodComponent } from './components/invoice-related/invoice-dashboard-payment-method/invoice-dashboard-payment-method.component';
import { InvoiceDashboardTotalAmountComponent } from './components/invoice-related/invoice-dashboard-total-amount/invoice-dashboard-total-amount.component';
import { InvoiceDashboardCompanyBreakdownComponent } from './components/invoice-related/invoice-dashboard-company-breakdown/invoice-dashboard-company-breakdown.component';
import { InvoiceDashboardTotalAmountTabsComponent } from './components/invoice-related/invoice-dashboard-total-amount-tabs/invoice-dashboard-total-amount-tabs.component';
import { InvoiceDashboardStatsComponent } from './components/invoice-related/invoice-dashboard-stats/invoice-dashboard-stats.component';
import { InvoiceDetailComponent } from './components/invoice-related/invoice-detail/invoice-detail.component';
import { InvoiceDetailStatsComponent } from './components/invoice-related/invoice-detail/invoice-detail-stats/invoice-detail-stats.component';
import { InvoiceDetailListComponent } from './components/invoice-related/invoice-detail/invoice-detail-list/invoice-detail-list.component';
import { CustomerGaugeChartComponent } from './components/customer-related/customer-gauge-chart/customer-gauge-chart.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { GradeSettingComponent } from './components/settings-related/challenger-score/grade-setting/grade-setting.component';
import { RangeDeductScoreComponent } from './components/settings-related/challenger-score/range-deduct-score/range-deduct-score.component';
import { RatioMaxPointComponent } from './components/settings-related/challenger-score/ratio-max-point/ratio-max-point.component';
import { ScoreSettingsComponent } from './components/settings-related/challenger-score/score-settings/score-settings.component';
import { InventoryItemStatsComponent } from './components/others-related/inventory-related/inventory-item-stats/inventory-item-stats.component';
import { InventoryItemListComponent } from './components/others-related/inventory-related/inventory-item-list/inventory-item-list.component';
import { AddInventoryItemComponent } from './components/others-related/inventory-related/add-inventory-item/add-inventory-item.component';
import { CreateTicketComponent } from './components/ticket-related/create-ticket/create-ticket.component';
import { TicketDetailsComponent } from './components/ticket-related/ticket-details/ticket-details.component';
import { TicketListComponent } from './components/ticket-related/ticket-list/ticket-list.component';
import { TicketUpdateComponent } from './components/ticket-related/ticket-update/ticket-update.component';
import { FormatFileSizePipe } from './pipe/format-file-size.pipe';
import { ShowIconPipe } from './pipe/show-icon.pipe';
import { CustomerScoreStatsModule } from '../blockModules/pages/customer-score-stats/customer-score-stats.module';
import { AddUpdateInventoryCategoryComponent } from './components/others-related/inventory-related/add-update-inventory-category/add-update-inventory-category.component';
import { InventoryCategoryListComponent } from './components/others-related/inventory-related/inventory-category-list/inventory-category-list.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HostedPaymentPageSettingLayoutComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-setting-layout/hosted-payment-page-setting-layout.component';
import { HostedPaymentPageConfigSettingComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-config-setting/hosted-payment-page-config-setting.component';
import { HostedPaymentPagePreviewComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-preview/hosted-payment-page-preview.component';
import { HostedPaymentPageWebPreviewComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-preview/hosted-payment-page-web-preview/hosted-payment-page-web-preview.component';
import { HostedPaymentPageTabPreviewComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-preview/hosted-payment-page-tab-preview/hosted-payment-page-tab-preview.component';
import { HostedPaymentPageMobilePreviewComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-preview/hosted-payment-page-mobile-preview/hosted-payment-page-mobile-preview.component';
import { HostedPaymentLinkConfigurationComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/hosted-payment-link-configuration.component';
import { DesignTabComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/tab-control/design-tab/design-tab.component';
import { SettingTabComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/tab-control/setting-tab/setting-tab.component';
import { PricingTabComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/tab-control/pricing-tab/pricing-tab.component';
import { ButtonPreviewComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/button-preview-and-code/button-preview/button-preview.component';
import { HtmlCodeComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/button-preview-and-code/html-code/html-code.component';
import { TabControlComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/tab-control/tab-control.component';
import { ButtonPreviewAndCodeComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-configuration/button-preview-and-code/button-preview-and-code.component';
import { HostedPaymentLinkListComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-list/hosted-payment-link-list.component';
import { HostedPaymentPageSettingMasterLayoutComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-setting-master-layout/hosted-payment-page-setting-master-layout.component';
import { DesignCustomisationComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-config-setting/hosted-payment-tabs/design-customisation/design-customisation.component';
import { ConfigurationComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-page-config-setting/hosted-payment-tabs/configuration/configuration.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PaymentLinkAdvanceFilterComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-list/payment-link-advance-filter/payment-link-advance-filter.component';
import { PaymentLinkViewDetailsComponent } from './components/hosted-payment-setting-related/hosted-payment-link-setting/hosted-payment-link-list/payment-link-view-details/payment-link-view-details.component';
import { HostedPageBtmPreviewComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-page-btm-preview/hosted-page-btm-preview.component';
import { HostedPaymentPageOrderComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-pages/hosted-payment-page-order/hosted-payment-page-order.component';
import { HostedPaymentPageInformationComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-pages/hosted-payment-page-information/hosted-payment-page-information.component';
import { HostedPaymentPageSuccessComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-payment-pages/hosted-payment-page-success/hosted-payment-page-success.component';
import { HostedPaymentPageBtmPreviewTabComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-page-btm-preview/hosted-payment-page-btm-preview-tab/hosted-payment-page-btm-preview-tab.component';
import { HostedPaymentPageBtmPreviewMobileComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-page-btm-preview/hosted-payment-page-btm-preview-mobile/hosted-payment-page-btm-preview-mobile.component';
import { HostedPaymentPageBtmPreviewWebComponent } from './components/hosted-payment-setting-related/hosted-payment-page-setting/hosted-page-btm-preview/hosted-payment-page-btm-preview-web/hosted-payment-page-btm-preview-web.component';
import { NsfVsPaidTransactionComponent } from './components/instant-verify-related/instant-verify-report/reports-component/nsf-vs-paid-transaction/nsf-vs-paid-transaction.component';


import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { RecurringDebitsComponent } from './components/instant-verify-related/instant-verify-report/reports-component/recurring-debits/recurring-debits.component';
import { BillingListComponent } from './components/billing-related/billing-list/billing-list.component';
import { BillingProfileComponent } from './components/billing-related/billing-profile/billing-profile.component';
import { OriginationReportComponent } from './components/report-related/origination-report/origination-report.component';
import { OriginationReportFilterComponent } from './components/report-related/origination-report-filter/origination-report-filter.component';
import { CashFlowAnalysisComponent } from './components/instant-verify-related/instant-verify-report/reports-component/cash-flow-analysis/cash-flow-analysis.component';
import { PotentialLoansComponent } from './components/instant-verify-related/instant-verify-report/reports-component/potential-loans/potential-loans.component';
import { RecurringCreditsComponent } from './components/instant-verify-related/instant-verify-report/reports-component/recurring-credits/recurring-credits.component';
import { AddCustomerFromCustomergroupComponent } from './components/customer-related/add-customer-from-customergroup/add-customer-from-customergroup.component';
import { CustomerGroupComponent } from './components/customer-related/customer-group/customer-group.component';
import { ViewCustomerGroupComponent } from './components/customer-related/view-customer-group/view-customer-group.component';

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppMaterialModule,
    SharedModule,
    NgxOtpInputModule,
    IntlInputPhoneModule,
    Ng5SliderModule,
    NgxIntlTelInputModule,
    NgChartsModule,
    GaugeChartModule,
    CustomerScoreStatsModule,
    NgMultiSelectDropDownModule,
    NgxMaterialTimepickerModule,
    ColorPickerModule,
    ClipboardModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  declarations: [
    AddAchTransactionComponent,
    CustomerProfileComponent,
    CustomerDetailsComponent,
    BankAndCardDetailsComponent,
    CustomerTransactionsComponent,
    BankAccountsComponent,
    DebitCreditCardsComponent,
    VirtualCardsComponent,
    AddCustomerComponent,
    SendOtpComponent,
    CustomerRefreshDetailsComponent,
    AddCustomerComponent,
    AddUserComponent,
    EditUserComponent,
    AddCreditdebitTransactionComponent,
    AddInstantBankVerifyComponent,
    CustomerCreditdebitTransactionComponent,
    InstantVerifyReportComponent,
    InstantVerifyReportDDAComponent,
    InstantVerifyReportSDAComponent,
    InstantVerifyReportCCAComponent,
    InstantVerifyReportINVComponent,
    InstantVerifyReportBPAComponent,
    BankQuickViewComponent,
    ReportTransactionsComponent,
    CCABalanceCardComponent,
    InvBalanceCardComponent,
    BpaBalanceCardComponent,
    AchTransactionsSummaryComponent,
    CustomerAchTransactionComponent,
    EcheckTransactionsSummaryComponent,
    CreditdebitTransactionsSummaryComponent,
    AddEcheckTransactionComponent,
    CalenderSliderCardComponent,
    CustomerEcheckTransactionComponent,
    DashboardStatsComponent,
    DashboardTotalTransactionsComponent,
    DashboardRecurringTransactionsComponent,
    DashboardProductsReportsComponent,
    DashboardAchTransactionReportComponent,
    DashboardCreditdebitTransactionReportComponent,
    DashboardInstantfundTransactionReportComponent,
    DashboardEcheckTransactionReportComponent,
    DashboardPieChartReportsComponent,
    DashboardTransactionAmountChartComponent,
    DashboardReportTotalTransactionsComponent,
    DashboardPieChartComponent,
    DashboardReportServiceBasedComponent,
    InstantFundTransactionsSummaryComponent,
    CustomerInstantFundTransactionComponent,
    AddInstantFundTransactionComponent,
    DashboardReportReturnRateComponent,
    DashboardReportTransactionStatusComponent,
    DashboardReportTotalAmountComponent,
    GenerateReportsComponent,
    ReportsStatsComponent,
    ReportAmountTransactionComponent,
    ReportsRecurringComponent,
    ReportTotalTransactionComponent,
    ReportTransactionAmountChartComponent,
    GenerateReportGridComponent,
    FlagsComponent,
    EmployerVerificationComponent,
    RecurringDebitsComponent,
    DepositsComponent,
    RecuringWithdrawalsComponent,
    DtiQuickViewComponent,
    FinzeoGradeComponent,
    PotentialPaymentAmountsComponent,
    DrawArcComponent,
    DrawLinearGuageComponent,
    AccountSummaryComponent,
    StatementHistoryComponent,
    CurrentStatementComponent,
    GenerateInvoiceComponent,
    DynamicInvoiceComponent,
    InvoiceSetupComponent,
    EmailSetupComponent,
    ManageNotificationComponent,
    SettingsComponent,
    InvoicePreviewComponent,
    InvoicePreviewDarkComponent,
    InvoiceDashboardStatsComponent,
    InvoiceDashboardTotalInvoicesComponent,
    InvoiceDashboardPaymentMethodComponent,
    InvoiceDashboardTotalAmountComponent,
    InvoiceDashboardCompanyBreakdownComponent,
    InvoiceDashboardTotalAmountTabsComponent,
    InvoiceDetailComponent,
    InvoiceDetailStatsComponent,
    InvoiceDetailListComponent,
    CustomerGaugeChartComponent,
    SettingsComponent,
    ScoreSettingsComponent,
    RatioMaxPointComponent,
    GradeSettingComponent,
    RangeDeductScoreComponent,
    InvoiceDetailListComponent,
    InventoryItemStatsComponent,
    InventoryItemListComponent,
    InventoryItemListComponent,
    AddInventoryItemComponent,
    CreateTicketComponent,
    TicketDetailsComponent,
    TicketListComponent,
    TicketUpdateComponent,
    FormatFileSizePipe,
    ShowIconPipe,
    HostedPaymentPageSettingLayoutComponent,
    HostedPaymentLinkConfigurationComponent,
    HostedPaymentPageConfigSettingComponent,
    HostedPaymentPagePreviewComponent,
    HostedPaymentPageWebPreviewComponent,
    HostedPaymentPageTabPreviewComponent,
    HostedPaymentPageMobilePreviewComponent,
    AddUpdateInventoryCategoryComponent,
    InventoryCategoryListComponent,
    HostedPaymentLinkConfigurationComponent,
    DesignTabComponent,
    SettingTabComponent,
    PricingTabComponent,
    ButtonPreviewComponent,
    HtmlCodeComponent,
    TabControlComponent,
    ButtonPreviewAndCodeComponent,
    HostedPaymentLinkListComponent,
    HostedPaymentPageSettingMasterLayoutComponent,
    DesignCustomisationComponent,
    ConfigurationComponent,
    PaymentLinkAdvanceFilterComponent,
    PaymentLinkViewDetailsComponent,
    HostedPageBtmPreviewComponent,
    HostedPaymentPageOrderComponent,
    HostedPaymentPageInformationComponent,
    HostedPaymentPageSuccessComponent,
    HostedPaymentPageBtmPreviewTabComponent,
    HostedPaymentPageBtmPreviewMobileComponent,
    HostedPaymentPageBtmPreviewWebComponent,
    NsfVsPaidTransactionComponent,
    BillingListComponent,
    BillingProfileComponent,
    OriginationReportComponent,
    OriginationReportFilterComponent ,
    CashFlowAnalysisComponent,
    PotentialLoansComponent,
    AddCustomerFromCustomergroupComponent,
    RecurringCreditsComponent,
    CustomerGroupComponent,
    ViewCustomerGroupComponent    
  ],
  providers: [InstantVerifyReportsService],
  exports: [
    AddAchTransactionComponent,
    CustomerProfileComponent,
    CustomerDetailsComponent,
    BankAndCardDetailsComponent,
    CustomerTransactionsComponent,
    BankAccountsComponent,
    DebitCreditCardsComponent,
    VirtualCardsComponent,
    CustomerRefreshDetailsComponent,
    AchTransactionsSummaryComponent,
    EcheckTransactionsSummaryComponent,
    CreditdebitTransactionsSummaryComponent,
    DashboardStatsComponent,
    DashboardTotalTransactionsComponent,
    DashboardRecurringTransactionsComponent,
    DashboardProductsReportsComponent,
    DashboardAchTransactionReportComponent,
    DashboardCreditdebitTransactionReportComponent,
    DashboardInstantfundTransactionReportComponent,
    DashboardEcheckTransactionReportComponent,
    DashboardPieChartReportsComponent,
    DashboardTransactionAmountChartComponent,
    DashboardPieChartComponent,
    InstantFundTransactionsSummaryComponent,
    CustomerInstantFundTransactionComponent,
    AddInstantFundTransactionComponent,
    DashboardReportReturnRateComponent,
    DashboardReportTransactionStatusComponent,
    DashboardReportTotalAmountComponent,
    GenerateReportsComponent,
    ReportsStatsComponent,
    ReportAmountTransactionComponent,
    ReportsRecurringComponent,
    ReportTotalTransactionComponent,
    ReportTransactionAmountChartComponent,
    GenerateReportGridComponent,
    StatementHistoryComponent,
    CurrentStatementComponent,
    InvoiceDashboardStatsComponent,
    InvoiceDashboardTotalInvoicesComponent,
    InvoiceDashboardPaymentMethodComponent,
    InvoiceDashboardTotalAmountComponent,
    InvoiceDashboardCompanyBreakdownComponent,
    InvoiceDashboardTotalAmountTabsComponent,
    SettingsComponent,
    ScoreSettingsComponent,
    RatioMaxPointComponent,
    GradeSettingComponent,
    RangeDeductScoreComponent,
    SettingsComponent,
    InventoryItemStatsComponent,
    InventoryItemListComponent,
    AddInventoryItemComponent,
    TicketListComponent,
    TicketUpdateComponent,
    AddUpdateInventoryCategoryComponent,
    InventoryCategoryListComponent,
    HostedPaymentPageSettingMasterLayoutComponent,
    HostedPaymentLinkListComponent,
    BillingListComponent    
  ],
})
export class FeatureModule {}
